import axios from "axios";
import {
  GET_AISUGGESTION_FAIL,
  GET_AISUGGESTION_REQUEST,
  GET_AISUGGESTION_SUCCESS,
  url,
} from "../constants/constants";

export const AISuggestionAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_AISUGGESTION_REQUEST });

    const response = await axios.get(
      `${url}50744?_fields=acf&acf_format=standard`
    );
    console.log(response);

    dispatch({ type: GET_AISUGGESTION_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_AISUGGESTION_FAIL, error });
  }
};
