import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hero from "../CommonComponent/Hero_SideBySide";
import Info from "../CommonComponent/Info";
import Footer from "../CommonComponent/Footer";
import DriveParticipation from "../CommonComponent/DriveParticipation";
import { scroll } from "../../utils/scrollTop";
import OurSolution from "../CommonComponent/OurSolution";
import { metaData } from "../../utils/metaData";
import { AIQAmatrixAction } from "../../redux/actions/AIQAmatrixAction";
import HowSteps from "../CommonComponent/HowSteps";

const AIQAmatrix = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    metaData("50746");
    scroll();
    dispatch(AIQAmatrixAction());
  }, [dispatch]);
  const aiqamatrixData = useSelector(
    (state) => state?.aiqamatrix?.aiqamatrix?.acf
  );
  console.log(aiqamatrixData);
  
  const hero = aiqamatrixData?.hero_banner_v2;
  const matrixworks =
  aiqamatrixData?.how_the_matrix_works_with_3_easy_steps;
  const {
    setup_and_result: { setup_and_result } = {},
    section_before_footer: info,
    our_solutions: oursolutions,
  } = aiqamatrixData || {};
  const text_before_title =
  aiqamatrixData?.setup_and_result?.text_before_title;
  const title = aiqamatrixData?.setup_and_result?.title;
  const description = aiqamatrixData?.setup_and_result?.description;
  const drive1 = setup_and_result?.right_image_align_section;
  const drive2 = setup_and_result?.left_image_align_section;

  return (
    <>
      <Hero data={hero}/>
      <DriveParticipation
        text_before_title={text_before_title}
        title={title}
        description={description}
        card1={drive1}
        card2={drive2}
      bgwhite={true}
      />
      <HowSteps data={matrixworks} />
      <OurSolution data={oursolutions} bggrey={true} />
      <Info
        title={info?.title}
        desc={info?.description}
        btn={info?.call_to_action_button?.title}
        // btn2={info?.call_to_action_button_2?.title}
        // bggrey={true}
      />
      <Footer />
    </>
  );
};

export default AIQAmatrix;
