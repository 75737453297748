// export const isValidEmail = (email) => {
//   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailPattern.test(email);
// };

import parsePhoneNumberFromString from "libphonenumber-js";

export const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

// export const isValidPhoneNumber = (phone) => {
//   // const phonePattern = /^\+[0-9]{1,3}\.[0-9]{4,14}(?:x.+)?$/;
//   const phonePattern = /^\+[1-9][0-9]{1,3}[0-9]{4,14}$/;
//   return phonePattern.test(phone);
// };

export const isValidPhoneNumber = (number) => {
  const phoneNumber = parsePhoneNumberFromString(number); // Specify country code if needed
  // console.log(phoneNumber);
  return phoneNumber && phoneNumber.isValid();
};