export function metaData(pageId) {
  return getMetaDataa(pageId);
}

export function resourceMetaData(pageId) {
  return getResourceMetaDataa(pageId);
}

export function getResourceMetaDataa(pageId) {
  return fetch(`https://theleansuite.com/api/resource-meta-data/${pageId}`)
  // return fetch(`http://localhost:5000/api/resource-meta-data/${pageId}`)
    .then((response) => response.json())
    .then((data) => {
      setMEtaData(data?.metaData);
      return data;
    })
    .catch((error) => {
      console.error("Error fetching metadata:", error);
      throw error; // Re-throw the error to be caught by the caller
    });
}

export function getMetaDataa(pageId) {
  return fetch(`https://theleansuite.com/api/meta-data/${pageId}`)
  // return fetch(`http://localhost:5000/api/meta-data/${pageId}`)
    .then((response) => response.json())
    .then((data) => {
      setMEtaData(data?.metaData);
      return data;
    })
    .catch((error) => {
      console.error("Error fetching metadata:", error);
      throw error; // Re-throw the error to be caught by the caller
    });
}

function setMEtaData(apiData) {
  document.head.querySelector("title").innerText = apiData?.title || "";
  document.head.querySelector("meta[name='description']").content =
    apiData?.description || "";
  document.head.querySelector("link[rel='canonical']").href =
    apiData?.canonical || "";
  document.head.querySelector("meta[name='keywords']").content =
    apiData?.keywords || "";
  document.head.querySelector("meta[name='author']").content =
    apiData?.author || "";
  document.head.querySelector("meta[property='og:title']").content =
    apiData?.title || "";
  document.head.querySelector("meta[property='og:description']").content =
    apiData?.description || "";
  document.head.querySelector("meta[property='og:image']").content =
    apiData?.og_image?.[0]?.url || "";
  document.head.querySelector("meta[property='og:url']").content =
    apiData?.og_site_name || "";
  document.head.querySelector("meta[property='og:site_name']").content =
    apiData?.og_url || "";
  document.head.querySelector("meta[name='twitter:title']").content =
    apiData?.title || "";
  document.head.querySelector("meta[name='twitter:description']").content =
    apiData?.description || "";
  document.head.querySelector("meta[name='twitter:image']").content =
    apiData?.og_image?.[0]?.url || "";
  document.head.querySelector("meta[name='twitter:card']").content =
    apiData?.twitter_card || "";
  document.head.querySelector("meta[name='twitter:site']").content =
    apiData?.twitter_site || "";
}
