// export const url = "https://34.135.75.163/backend/wp-json/wp/v2/pages/";
export const url =
  "https://staging.theleansuite.com/backend/wp-json/wp/v2/pages/";
export const blogUrl =
  "https://staging.theleansuite.com/backend/wp-json/wp/v2/posts";

export const URL = "https://staging.theleansuite.com/backend/wp-json/wp/v2";

//   https://staging.theleansuite.com/backend/wp-json/wp/v2/posts?status=publish&per_page=6

// https://34.135.75.163/backend/wp-json/wp/v2/pages/9616?_fields=acf&acf_format=standard

export const API_URL="https://staging.theleansuite.com/backend/wp-json/"

export const edgelogo =
  "https://staging.theleansuite.com/backend/wp-content/uploads/2023/09/edge_logo.webp";
export const apmalogo =
  "https://staging.theleansuite.com/backend/wp-content/uploads/2023/09/apma_logo.webp";
export const nationallogo =
  "https://staging.theleansuite.com/backend/wp-content/uploads/2023/09/national_association_of_manufacturers_logo.webp";
export const acceleratorlogo =
  "https://staging.theleansuite.com/backend/wp-content/uploads/2023/09/the_accelerator_centre_logo.webp";

export const SEARCH_TEXT = "SEARCH_TEXT";
export const FILTER_TEXT = "FILTER_TEXT";
export const OPEN_NAVBAR = "OPEN_NAVBAR";

export const GET_LANDING_DATA_REQUEST = "GET_LANDING_DATA_REQUEST";
export const GET_LANDING_DATA_SUCCESS = "GET_LANDING_DATA_SUCCESS";
export const GET_LANDING_DATA_FAIL = "GET_LANDING_DATA_FAIL";

export const GET_ABOUT_PAGE_REQUEST = "GET_ABOUT_PAGE_REQUEST";
export const GET_ABOUT_PAGE_SUCCESS = "GET_ABOUT_PAGE_SUCCESS";
export const GET_ABOUT_PAGE_FAIL = "GET_ABOUT_PAGE_FAIL";

export const GET_WHAT_IS_LEAN_REQUEST = "GET_WHAT_IS_LEAN_REQUEST";
export const GET_WHAT_IS_LEAN_SUCCESS = "GET_WHAT_IS_LEAN_SUCCESS";
export const GET_WHAT_IS_LEAN_FAIL = "GET_WHAT_IS_LEAN_FAIL";

export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

export const GET_BLOG_LIST_REQUEST = "GET_BLOG_LIST_REQUEST";
export const GET_BLOG_LIST_SUCCESS = "GET_BLOG_LIST_SUCCESS";
export const GET_BLOG_LIST_FAIL = "GET_BLOG_LIST_FAIL";

export const GET_ALL_BLOG_REQUEST = "GET_ALL_BLOG_REQUEST";
export const GET_ALL_BLOG_SUCCESS = "GET_ALL_BLOG_SUCCESS";
export const GET_ALL_BLOG_FAIL = "GET_ALL_BLOG_FAIL";

export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";

export const GET_LATEST_BLOG_REQUEST = "GET_LATEST_BLOG_REQUEST";
export const GET_LATEST_BLOG_SUCCESS = "GET_LATEST_BLOG_SUCCESS";
export const GET_LATEST_BLOG_FAIL = "GET_LATEST_BLOG_FAIL";

export const GET_SINGLE_BLOG_REQUEST = "GET_SINGLE_BLOG_REQUEST";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_FAIL = "GET_SINGLE_BLOG_FAIL";

export const GET_RECENT_BLOG_REQUEST = "GET_RECENT_BLOG_REQUEST";
export const GET_RECENT_BLOG_SUCCESS = "GET_RECENT_BLOG_SUCCESS";
export const GET_RECENT_BLOG_FAIL = "GET_RECENT_BLOG_FAIL";

export const GET_ALL_BLOGS_REQUEST = "GET_ALL_BLOGS_REQUEST";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAIL = "GET_ALL_BLOGS_FAIL";

export const GET_CATEGORY_BLOG_REQUEST = "GET_CATEGORY_BLOG_REQUEST";
export const GET_CATEGORY_BLOG_SUCCESS = "GET_CATEGORY_BLOG_SUCCESS";
export const GET_CATEGORY_BLOG_FAIL = "GET_CATEGORY_BLOG_FAIL";

export const GET_ATTACHMENT_REQUEST = "GET_ATTACHMENT_REQUEST";
export const GET_ATTACHMENT_SUCCESS = "GET_ATTACHMENT_SUCCESS";
export const GET_ATTACHMENT_FAIL = "GET_ATTACHMENT_FAIL";

export const GET_CAMPAIGN_REQUEST = "GET_CAMPAIGN_REQUEST";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL";

export const GET_POINT_LEADER_REQUEST = "GET_POINT_LEADER_REQUEST";
export const GET_POINT_LEADER_SUCCESS = "GET_POINT_LEADER_SUCCESS";
export const GET_POINT_LEADER_FAIL = "GET_POINT_LEADER_FAIL";

export const GET_SUGGESTION_OPERATION_REQUEST =
  "GET_SUGGESTION_OPERATION_REQUEST";
export const GET_SUGGESTION_OPERATION_SUCCESS =
  "GET_SUGGESTION_OPERATION_SUCCESS";
export const GET_SUGGESTION_OPERATION_FAIL = "GET_SUGGESTION_OPERATION_FAIL";

export const GET_LMS_REQUEST = "GET_LMS_REQUEST";
export const GET_LMS_SUCCESS = "GET_LMS_SUCCESS";
export const GET_LMS_FAIL = "GET_LMS_FAIL";

export const GET_ROLES_PERMISSIONS_FAIL = "GET_ROLES_PERMISSION_FAIL";
export const GET_ROLES_PERMISSIONS_REQUEST = "GET_ROLES_PERMISSION_REQUEST";
export const GET_ROLES_PERMISSIONS_SUCCESS = "GET_ROLES_PERMISSION_SUCCESS";

export const GET_TEMPLATE_BUILDER_FAIL = "GET_TEMPLATE_BUILDER_FAIL";
export const GET_TEMPLATE_BUILDER_REQUEST = "GET_TEMPLATE_BUILDER_REQUEST";
export const GET_TEMPLATE_BUILDER_SUCCESS = "GET_TEMPLATE_BUILDER_SUCCESS";

export const GET_PARTNERS_FAIL = "GET_PARTNERS_FAIL";
export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";

export const GET_QA_MATRIX_FAIL = "GET_QA_MATRIX_FAIL";
export const GET_QA_MATRIX_REQUEST = "GET_QA_MATRIX_REQUEST";
export const GET_QA_MATRIX_SUCCESS = "GET_QA_MATRIX_SUCCESS";

export const GET_SUGGESTION_REQUEST = "GET_SUGGESTION_REQUEST";
export const GET_SUGGESTION_SUCCESS = "GET_SUGGESTION_SUCCESS";
export const GET_SUGGESTION_FAIL = "GET_SUGGESTION_FAIL";

export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAIL = "GET_CONTACT_FAIL";

export const GET_PRICING_REQUEST = "GET_PRICING_REQUEST";
export const GET_PRICING_SUCCESS = "GET_PRICING_SUCCESS";
export const GET_PRICING_FAIL = "GET_PRICING_FAIL";

export const GET_CAREERS_FAIL = "GET_CAREERS_FAIL";
export const GET_CAREERS_REQUEST = "GET_CAREERS_REQUEST";
export const GET_CAREERS_SUCCESS = "GET_CAREERS_SUCCESS";

export const GET_VIEW_OPP_FAIL = "GET_VIEW_OPP_FAIL";
export const GET_VIEW_OPP_REQUEST = "GET_VIEW_OPP_REQUEST";
export const GET_VIEW_OPP_SUCCESS = "GET_VIEW_OPP_SUCCESS";

export const GET_CONTINUOUSIMPROVEMENT_FAIL = "GET_CONTINUOUSIMPROVEMENT_FAIL";
export const GET_CONTINUOUSIMPROVEMENT_REQUEST =
  "GET_CONTINUOUSIMPROVEMENT_REQUEST";
export const GET_CONTINUOUSIMPROVEMENT_SUCCESS =
  "GET_CONTINUOUSIMPROVEMENT_SUCCESS";

export const GET_PRESS_FAIL = "GET_PRESS_FAIL";
export const GET_PRESS_REQUEST = "GET_PRESS_REQUEST";
export const GET_PRESS_SUCCESS = "GET_PRESS_SUCCESS";

export const GET_RCA_FAIL = "GET_RCA_FAIL";
export const GET_RCA_REQUEST = "GET_RCA_REQUEST";
export const GET_RCA_SUCCESS = "GET_RCA_SUCCESS";

export const GET_COMPETENCY_FAIL = "GET_COMPETENCY_FAIL";
export const GET_COMPETENCY_REQUEST = "GET_COMPETENCY_REQUEST";
export const GET_COMPETENCY_SUCCESS = "GET_COMPETENCY_SUCCESS";

export const GET_PRESS_LIST_FAIL = "GET_PRESS_LIST_FAIL";
export const GET_PRESS_LIST_REQUEST = "GET_PRESS_LIST_REQUEST";
export const GET_PRESS_LIST_SUCCESS = "GET_PRESS_LIST_SUCCESS";

export const GET_ISSUE_TRACKER_REQUEST = "GET_ISSUE_TRACKER_REQUEST";
export const GET_ISSUE_TRACKER_SUCCESS = "GET_ISSUE_TRACKER_SUCCESS";
export const GET_ISSUE_TRACKER_FAIL = "GET_ISSUE_TRACKER_FAIL";

export const GET_OPERATION_REQUEST = "GET_OPERATION_REQUEST";
export const GET_OPERATION_SUCCESS = "GET_OPERATION_SUCCESS";
export const GET_OPERATION_FAIL = "GET_OPERATION_FAIL";

export const GET_MANAGEMENT_QUALITY_REQUEST = "GET_MANAGEMENT_QUALITY_REQUEST";
export const GET_MANAGEMENT_QUALITY_SUCCESS = "GET_MANAGEMENT_QUALITY_SUCCESS";
export const GET_MANAGEMENT_QUALITY_FAIL = "GET_MANAGEMENT_QUALITY_FAIL";

export const GET_BLOGS_LIST_REQUEST = "GET_BLOGS_LIST_REQUEST";
export const GET_BLOGS_LIST_SUCCESS = "GET_BLOGS_LIST_SUCCESS";
export const GET_BLOGS_LIST_FAIL = "GET_BLOGS_LIST_FAIL";

export const GET_SINGLE_BLOGS_LIST_REQUEST = "GET_SINGLE_BLOGS_LIST_REQUEST";
export const GET_SINGLE_BLOGS_LIST_SUCCESS = "GET_SINGLE_BLOGS_LIST_SUCCESS";
export const GET_SINGLE_BLOGS_LIST_FAIL = "GET_SINGLE_BLOGS_LIST_FAIL";

export const GET_SINGLE_CATEGORY_LIST_REQUEST = "GET_SINGLE_CATEGORY_LIST_REQUEST";
export const GET_SINGLE_CATEGORY_LIST_SUCCESS = "GET_SINGLE_CATEGORY_LIST_SUCCESS";
export const GET_SINGLE_CATEGORY_LIST_FAIL = "GET_SINGLE_CATEGORY_LIST_FAIL";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const GET_SAFETY_IMPROVEMENT_REQUEST = "GET_SAFETY_IMPROVEMENT_REQUEST";
export const GET_SAFETY_IMPROVEMENT_SUCCESS = "GET_SAFETY_IMPROVEMENT_SUCCESS";
export const GET_SAFETY_IMPROVEMENT_FAIL = "GET_SAFETY_IMPROVEMENT_FAIL";

export const GET_QUALITY_IMPROVEMENT_REQUEST = "GET_QUALITY_IMPROVEMENT_REQUEST";
export const GET_QUALITY_IMPROVEMENT_SUCCESS = "GET_QUALITY_IMPROVEMENT_SUCCESS";
export const GET_QUALITY_IMPROVEMENT_FAIL = "GET_QUALITY_IMPROVEMENT_FAIL";

export const GET_OPERATIONAL_EXCELLENCE_REQUEST = "GET_OPERATIONAL_EXCELLENCE_REQUEST";
export const GET_OPERATIONAL_EXCELLENCE_SUCCESS = "GET_OPERATIONAL_EXCELLENCE_SUCCESS";
export const GET_OPERATIONAL_EXCELLENCE_FAIL = "GET_OPERATIONAL_EXCELLENCE_FAIL";

export const GET_COST_REDUCTION_REQUEST = "GET_COST_REDUCTION_REQUEST";
export const GET_COST_REDUCTION_SUCCESS = "GET_COST_REDUCTION_SUCCESS";
export const GET_COST_REDUCTION_FAIL = "GET_COST_REDUCTION_FAIL";

export const GET_WORKFORCE_ENABLEMENT_REQUEST = "GET_WORKFORCE_ENABLEMENT_REQUEST";
export const GET_WORKFORCE_ENABLEMENT_SUCCESS = "GET_WORKFORCE_ENABLEMENT_SUCCESS";
export const GET_WORKFORCE_ENABLEMENT_FAIL = "GET_WORKFORCE_ENABLEMENT_FAIL";


export const GET_ALL_RESOURCES_LIST_FAIL = "GET_ALL_RESOURCES_LIST_FAIL";
export const GET_ALL_RESOURCES_LIST_SUCCESS = "GET_ALL_RESOURCES_LIST_SUCCESS";
export const GET_ALL_RESOURCES_LIST_REQUEST = "GET_ALL_RESOURCES_LIST_REQUEST";

export const GET_PRIVACY_FAIL = "GET_PRIVACY_FAIL";
export const GET_PRIVACY_SUCCESS = "GET_PRIVACY_SUCCESS";
export const GET_PRIVACY_REQUEST = "GET_PRIVACY_REQUEST";

export const GET_TERMS_FAIL = "GET_TERMS_FAIL";
export const GET_TERMS_SUCCESS = "GET_TERMS_SUCCESS";
export const GET_TERMS_REQUEST = "GET_TERMS_REQUEST";

export const GET_GEMBA_WALKER_FAIL = "GET_GEMBA_WALKER_FAIL";
export const GET_GEMBA_WALKER_REQUEST = "GET_GEMBA_WALKER_REQUEST";
export const GET_GEMBA_WALKER_SUCCESS = "GET_GEMBA_WALKER_SUCCESS";

export const GET_CILR_FAIL = "GET_CILR_FAIL";
export const GET_CILR_REQUEST = "GET_CILR_REQUEST";
export const GET_CILR_SUCCESS = "GET_CILR_SUCCESS";

export const GET_CUSTOMIZABLE_FIELDS_FAIL = "GET_CUSTOMIZABLE_FIELDS_FAIL";
export const GET_CUSTOMIZABLE_FIELDS_REQUEST = "GET_CUSTOMIZABLE_FIELDS_REQUEST";
export const GET_CUSTOMIZABLE_FIELDS_SUCCESS = "GET_CUSTOMIZABLE_FIELDS_SUCCESS";

export const GET_MARKETPLACE_FAIL = "GET_MARKETPLACE_FAIL";
export const GET_MARKETPLACE_REQUEST = "GET_MARKETPLACE_REQUEST";
export const GET_MARKETPLACE_SUCCESS = "GET_MARKETPLACE_SUCCESS";

export const GET_JOB_COVER_MATRIX_FAIL = "GET_JOB_COVER_MATRIX_FAIL";
export const GET_JOB_COVER_MATRIX_REQUEST = "GET_JOB_COVER_MATRIX_REQUEST";
export const GET_JOB_COVER_MATRIX_SUCCESS = "GET_JOB_COVER_MATRIX_SUCCESS";

export const GET_LOSS_COST_FAIL = "GET_LOSS_COST_FAIL";
export const GET_LOSS_COST_REQUEST = "GET_LOSS_COST_REQUEST";
export const GET_LOSS_COST_SUCCESS = "GET_LOSS_COST_SUCCESS";

export const GET_ALL_RESOURCES_POST_FAIL = "GET_ALL_RESOURCES_POST_FAIL";
export const GET_ALL_RESOURCES_POST_SUCCESS = "GET_ALL_RESOURCES_POST_SUCCESS";
export const GET_ALL_RESOURCES_POST_REQUEST = "GET_ALL_RESOURCES_POST_REQUEST";

export const GET_SINGLE_RESOURCES_LIST_SUCCESS = "GET_SINGLE_RESOURCES_LIST_SUCCESS";
export const GET_SINGLE_RESOURCES_LIST_FAIL = "GET_SINGLE_RESOURCES_LIST_FAIL";
export const GET_SINGLE_RESOURCES_LIST_REQUEST = "GET_SINGLE_RESOURCES_LIST_REQUEST";

export const GET_AISUGGESTION_SUCCESS = "GET_AISUGGESTION_SUCCESS";
export const GET_AISUGGESTION_FAIL = "GET_AISUGGESTION_FAIL";
export const GET_AISUGGESTION_REQUEST = "GET_AISUGGESTION_REQUEST";

export const GET_AIQAMATRIX_SUCCESS = "GET_AIQAMATRIX_SUCCESS";
export const GET_AIQAMATRIX_FAIL = "GET_AIQAMATRIX_FAIL";
export const GET_AIQAMATRIX_REQUEST = "GET_AIQAMATRIX_REQUEST";

export const GET_VIDEO_LANDING_DATA_SUCCESS = "GET_VIDEO_LANDING_DATA_SUCCESS";
export const GET_VIDEO_LANDING_DATA_FAIL = "GET_VIDEO_LANDING_DATA_FAIL";
export const GET_VIDEO_LANDING_DATA_REQUEST = "GET_VIDEO_LANDING_DATA_REQUEST";


export const CLEAR_ERRORS = "CLEAR_ERRORS";
