import {
  CLEAR_ERRORS,
  GET_AIQAMATRIX_FAIL,
  GET_AIQAMATRIX_REQUEST,
  GET_AIQAMATRIX_SUCCESS,
} from "../constants/constants";

const initialState = {
  aiqamatrix: [],
  isaiqamatrix: false,
  error: null,
};

const AIQAmatrixReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_AIQAMATRIX_REQUEST:
      return { ...state, isaiqamatrix: true };
    case GET_AIQAMATRIX_SUCCESS:
      return { ...state, isaiqamatrix: false, aiqamatrix: action.payload };
    case GET_AIQAMATRIX_FAIL:
      return { ...state, isaiqamatrix: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default AIQAmatrixReducer;
