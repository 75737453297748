import React, { useEffect, useRef, useState } from "react";
import { Accordion, CloseButton, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import StickyLogo from "../../../assets/NewSite/theleansuite-logo.jpg";
import Logo from "../../../assets/NewSite/white-logo.webp";
import "./Navbar.css";
import { styled } from "styled-components";
import { Color } from "../../../theme/Color";
import { Link, useHistory } from "react-router-dom";
import arrow from "../../../assets/sidebar/arrow.png";
import lean from "../../../assets/sidebar/lead.png";
import idea from "../../../assets/sidebar/idea.png";
import failure from "../../../assets/sidebar/Failure.png";
import kaizen from "../../../assets/sidebar/kaizen.png";
import competen from "../../../assets/sidebar/competen.png";
import training from "../../../assets/sidebar/Train.png";
import loss from "../../../assets/sidebar/loss.png";
import qa from "../../../assets/sidebar/qa.png";
import cilr from "../../../assets/sidebar/cilr.png";
import gemba from "../../../assets/sidebar/gemba.png";
import breakdown from "../../../assets/sidebar/break.png";
import best from "../../../assets/sidebar/best.png";
// import contenticon from "../../assets/sidebar/submenu_content_icon.webp";
// import ideaicon from "../../assets/sidebar/idea.webp";
// import failuretag from "../../assets/sidebar/failure_tag.webp";

import { Font } from "../../../theme/Font";
import { BigYellowLinkButton } from "../../DesignComponent/DesignComponents";

const NavbarComponent = (props) => {
  const Button = styled(Link)`
    // display: flex;
    // width: 160px;
    // height: 40px;
    text-transform: capitalize;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2px;
    border: 2px solid ${Color.yelow};
    background-color: ${Color.yelow};
    color: ${Color.theme_first};
    transition: 0.8s all;
    font-family: ${Font.basel};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    // margin: 0 1rem;
    transition: box-shadow .3s cubic-bezier(.25, .46, .45, .94);
    &:hover {
      background-color: ${Color.yelow} !important;
    color: ${Color.theme_first};
      // border-color: ${Color.yelow};
      box-shadow: 0 1px 5px rgba(150,150,150,.5);
    }
    @media (max-width: 767px) {
      margin: 1rem 0 0.8rem 0;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      margin: 0.5rem 0;
    }
  `;

  const CustomeSpan = styled.span`
    color: ${Color.theme_secound};
    font-family: ${Font.basel};
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  `;
  const Seelink = styled.a`
    color: ${Color.theme_secound};
    font-family: ${Font.basel};
    padding: 10px 32px;
    font-weight: bold;
    text-decoration-line: underline;
  `;

  // const dispatch = useDispatch();
  const navigate = useHistory();
  const [openProduct, setOpenProduct] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  // const isNav = useSelector((state) => state?.isNavOpen?.isOpen);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [outsideClicked, setOutsideClicked] = useState(false);
  const navbarRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (navbarRef.current && !navbarRef.current.contains(e.target)) {
      setOutsideClicked(true);
    } else {
      setOutsideClicked(false);
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleNavigate = (link) => {
    // dispatch(openNavbarMenu(false));
    navigate.push(link);
    setIsMenuOpen(false);
    setIsMobileMenuOpen(false);
  };

  const handleMouseEnter = (menuItem) => {
    setIsMenuOpen(true);
    setOpenProduct(menuItem);
  };

  const handleMouseLeave = () => {
    // setIsMenuOpen(false);
  };
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeModal = () => {
    setIsMenuOpen(false);
    setIsMobileMenuOpen(false);
    // console.log("closeModal--------");
  };


  const bookDemo = () => {
    closeModal();
    navigate.push("/schedule-demo");
    toggleMobileMenu();
  };

  const [isSticky, setSticky] = useState(false);
  const [logoSrc, setLogoSrc] = useState(Logo);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setSticky(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Update logo source when navigation becomes sticky
  useEffect(() => {
    if (isSticky) {
      setLogoSrc(StickyLogo); // Set logo source for sticky navigation
    } else {
      setLogoSrc(Logo); // Set logo source for default navigation
    }
  }, [isSticky]);

  const handleToggle = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  useEffect(() => {
    const navbarCollapse = document.getElementById('navbarSupportedContent');

    const handleCollapse = () => setIsMobileMenuOpen(false);
    const handleExpand = () => setIsMobileMenuOpen(true);

    if (navbarCollapse) {
      navbarCollapse.addEventListener('shown.bs.collapse', handleExpand);
      navbarCollapse.addEventListener('hidden.bs.collapse', handleCollapse);
    }

    return () => {
      if (navbarCollapse) {
        navbarCollapse.removeEventListener('shown.bs.collapse', handleExpand);
        navbarCollapse.removeEventListener('hidden.bs.collapse', handleCollapse);
      }
    };
  }, []);

  return (
    <>
      <Navbar
        expand="xl"
        className={`navbar-dark py-3 desktop-nav d-xl-block d-none ${isSticky ? 'sticky' : ''}`}
        style={{ backgroundColor: isSticky ? '#fff' : '#1c1c4b' }}
      >
        <Container>
          <Navbar.Brand>
            <Link to={"/"}>
              <img src={logoSrc} alt="theleansuite logo" style={{ maxWidth: "145px" }} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item
                className=""
                onClick={() => handleMouseEnter("mainPlatform")}
                onMouseLeave={handleMouseLeave}
              >
                <Nav.Link
                  eventKey="1"
                  className="link-nav"
                  onClick={() => setActiveMenuItem("Platform")}
                  onMouseLeave={() => setActiveMenuItem("Platform")}
                // style={{
                //   background:
                //     activeMenuItem === "Platform"
                //       ? Color.lightBlue
                //       : "initial",
                // }}
                >
                  {/* <p
                    style={{
                      background:
                        activeMenuItem === "Platform"
                          ? Color.lightBlue
                          : "initial",
                    }}
                  > */}
                  Platform
                  {/* </p> */}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                // onMouseEnter={() => handleMouseEnter("mainCompany")}
                onClick={() => handleMouseEnter("mainCompany")}
                onMouseLeave={handleMouseLeave}
                // onMouseLeave={handleMouseLeave}
                className=""
              >
                <Nav.Link eventKey="1" className="link-nav">
                  Company
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className=""
                // onMouseEnter={() => handleMouseEnter("mainLearnig")}
                // onMouseLeave={handleMouseLeave}
                onClick={() => handleMouseEnter("mainLearnig")}
                onMouseLeave={handleMouseLeave}
              >
                <Nav.Link eventKey="1" className="link-nav">
                  Resources
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  eventKey="1"
                  as={Link}
                  to="/home"
                  className="link-nav"
                  style={{ display: "none" }}
                >
                  Success
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link
                  eventKey="1"
                  as={Link}
                  onClick={closeModal}
                  to="/pricing"
                  className="link-nav"
                >
                  Pricing
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav.Link
              eventKey="1"
              // as={Link}
              // href={"https://theleansuite.app/"}
              href={"https://starter.theleansuite.com/sign-up"}
              className="link-nav"
              onClick={closeModal}
              // target="_blank"
            // target="_blank"
            >
              {/* Login */}
              Start free now
            </Nav.Link>
            <Button className="text-decoration-none" to={"/schedule-demo"}>Schedule demo</Button>
            {/* <Button2 className="sticky-watch-btn text-decoration-none" to={"/404"} onClick={() => handleNavigate("/404")}>Watch Demo</Button2> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        // onMouseEnter={handleMouseEnter}
        ref={navbarRef}
        onClick={() => setIsMenuOpen(false)}
        className={`menu-content pe-0 ${isMenuOpen ? "active" : ""}`}
      >
        {isMenuOpen === true && openProduct === "mainPlatform" && (
          <div className="your-platform-content h-100">
            <div className="back-container bg-white h-100"></div>
            <div className="container h-100">
              <div className="row h-100">
                <div
                  className="col-md-4 pt-40 bg-white submenu-container"
                  onMouseEnter={() => handleMouseEnter("mainPlatform")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: "4rem 0 0 0" }}
                >
                  <h1
                    className="fw-bold fs-36 bold-clr mb-4 pb-2"
                    style={{ fontFamily: Font.bold }}
                  >
                    Platform
                  </h1>
                  <ul className="list-group">
                    {/* <li
                      className="list-group-item border-0 pb-2 m-0"
                      onMouseEnter={() => setActiveMenuItem("LeanSuite")}
                      // onMouseLeave={() => setActiveMenuItem(null)}
                    >
                      <div className="icon-text">
                        <img src={lean} className="mr-20" alt={"LeanSuite 360"} />
                        <CustomeSpan className="fw-5">LeanSuite 360</CustomeSpan>
                      </div>
                      <img src={arrow} alt={"arrow"} />
                    </li> */}
                    <li
                      className="list-group-item border-0 pb-2 px-4 has-submenu"
                      onClick={() => handleNavigate("/what-is-leansuite")}
                      onMouseEnter={() => setActiveMenuItem("LeanSuite")}
                      style={{
                        background:
                          activeMenuItem === "LeanSuite"
                            ? Color.lightBlue
                            : "initial",
                        alignItems:
                          activeMenuItem === "Platform" ? "center" : "start",
                      }}
                    >
                      <div className="icon-text">
                        <img src={lean} alt={"lean"} className="mr-20" />
                        <CustomeSpan className="fw-5">LeanSuite 360</CustomeSpan>
                      </div>
                      <img src={arrow} alt={"arrow"} />
                    </li>
                    <li
                      className="list-group-item border-0 pb-2 px-4 has-submenu"
                      onMouseEnter={() => setActiveMenuItem("Idea")}
                      style={{
                        background:
                          activeMenuItem === "Idea"
                            ? Color.lightBlue
                            : "initial",
                        alignItems:
                          activeMenuItem === "Platform" ? "center" : "start",
                      }}
                    >
                      <div className="icon-text">
                        <img src={idea} alt={"idea"} className="mr-20" />
                        <CustomeSpan className="fw-5">Idea</CustomeSpan>
                      </div>
                      <img src={arrow} alt={"arrow"} />
                    </li>
                    <li
                      className="list-group-item border-0 pb-2 px-4 has-submenu"
                      onMouseEnter={() => setActiveMenuItem("Failure")}
                      // onMouseLeave={() => setActiveMenuItem(null)}
                      style={{
                        background:
                          activeMenuItem === "Failure"
                            ? Color.lightBlue
                            : "initial",
                        alignItems:
                          activeMenuItem === "Platform" ? "center" : "start",
                      }}
                    >
                      <div className="icon-text">
                        <img src={failure} alt={"failure"} className="mr-20" />
                        <CustomeSpan className="fw-5">Failure Tag</CustomeSpan>
                      </div>
                      <img src={arrow} alt={"arrow"} />
                    </li>
                    <li
                      className="list-group-item border-0 pb-2 px-4 has-submenu"
                      onMouseEnter={() => setActiveMenuItem("Kaizen")}
                      // onMouseLeave={() => setActiveMenuItem(null)}
                      style={{
                        background:
                          activeMenuItem === "Kaizen"
                            ? Color.lightBlue
                            : "initial",
                        display: "none",
                        alignItems:
                          activeMenuItem === "Platform" ? "center" : "start",
                      }}
                    >
                      <div className="icon-text">
                        <img src={kaizen} alt={"kaizen"} className="mr-20" />
                        <CustomeSpan className="fw-5">Kaizen</CustomeSpan>
                      </div>
                      <img src={arrow} alt={"arrow"} />
                    </li>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("/continuous-improvement-for-operations")}
                      to={"/continuous-improvement-for-operations"}
                      onMouseEnter={() => setActiveMenuItem("")}
                    >
                      <div className="icon-text">
                        <img src={kaizen} alt={"kaizen"} className="mr-20" />
                        <CustomeSpan className="fw-5">Kaizen</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("/competency-management")}
                      to={"/competency-management"}
                      onMouseEnter={() => setActiveMenuItem("")}
                    >
                      <div className="icon-text">
                        <img src={competen} alt={"competen"} className="mr-20" />
                        <CustomeSpan className="fw-5">Competencies</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"/learning-management-system"}
                      onMouseEnter={() => setActiveMenuItem("")}
                    >
                      <div className="icon-text">
                        <img src={training} alt={"training"} className="mr-20" />
                        <CustomeSpan className="fw-5">Training</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onMouseEnter={() => setActiveMenuItem("")}
                      // onClick={() => handleNavigate("404")}
                      to={"/loss-and-cost-management"}
                    >
                      <div className="icon-text">
                        <img src={loss} alt={"loss"} className="mr-20" />
                        <CustomeSpan className="fw-5">Loss and Cost Management</CustomeSpan>
                      </div>
                    </Link>
                    {/* <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onMouseEnter={() => setActiveMenuItem("404")}
                      to={"404"}
                    >
                      <div className="icon-text">
                        <img src={consttree} alt={"consttree"} className="mr-20" />
                        <CustomeSpan className="fw-5">Cost Tree</CustomeSpan>
                      </div>
                    </Link> */}
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onMouseEnter={() => setActiveMenuItem("")}
                      // onClick={() => handleNavigate("/qa-matrix")}
                      to={"/qa-matrix"}
                    >
                      <div className="icon-text">
                        <img src={qa} alt={"qa"} className="mr-20" />
                        <CustomeSpan className="fw-5">QA Matrix</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onMouseEnter={() => setActiveMenuItem("")}
                      // onClick={() => handleNavigate("/cilr-management")}
                      to={"/cilr-management"}
                    >
                      <div className="icon-text">
                        <img src={cilr} alt={"cilr"} className="mr-20" />
                        <CustomeSpan className="fw-5">CILR</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onMouseEnter={() => setActiveMenuItem("")}
                      // onClick={() => handleNavigate("/gemba-walker")}
                      to={"/gemba-walker"}
                    >
                      <div className="icon-text">
                        <img src={gemba} alt={"gemba"} className="mr-20" />
                        <CustomeSpan className="fw-5">Gemba Walker</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onMouseEnter={() => setActiveMenuItem("")}
                      // onClick={() => handleNavigate("/rca")}
                      to={"/rca"}
                    >
                      <div className="icon-text">
                        <img src={breakdown} alt={"breakdown"} className="mr-20" />
                        <CustomeSpan className="fw-5">
                          Root Cause Analysis
                        </CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 mb-4 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"/job-cover-matrix"}
                    >
                      <div className="icon-text">
                        <img src={best} alt={"best"} className="mr-20" />
                        <CustomeSpan className="fw-5">Job Cover Matrix</CustomeSpan>
                      </div>
                    </Link>
                    <li
                      className="list-group-item border-0 pb-2 px-4"
                      style={{ display: "none" }}
                    >
                      <Seelink as={Link} to="/">
                        See all platform pricing
                      </Seelink>
                    </li>
                  </ul>
                </div>

                {activeMenuItem != "" && (
                  <div
                    className="col-md-4 light-cream-clr pt-40 px-4 d-flex flex-wrap"
                    style={{
                      background: Color.theme_four,
                      alignItems:
                        activeMenuItem === "Platform" ? "center" : "start",
                    }}
                    onMouseEnter={() => handleMouseEnter("mainPlatform")}
                    onMouseLeave={handleMouseLeave}
                  >
                    {activeMenuItem === "Platform" && (
                      <div className="content-wrapper-platform">
                        <img
                          src={lean}
                          alt={"lean"}
                          className="mb-4 mx-auto d-block"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <div
                          className="title fw-bold fs-36 bold-clr mb-4 w-100 text-center"
                          style={{
                            fontFamily: Font.bold,
                          }}
                        >
                          Explore LeanSuite 360
                        </div>
                        <div className="description fs-6 text-center mb-4">
                          LeanSuite is a complete lean manufacturing software that allows you to centralize and enhance all your manufacturing business aspects into a single platform, transforming you into a paperless factory. Embrace the power of LeanSuite and unlock the full potential of your manufacturing business.
                        </div>
                        <BigYellowLinkButton to={"/what-is-leansuite"} className="w-100">Learn about LeanSuite</BigYellowLinkButton>
                      </div>
                    )}

                    {activeMenuItem === "LeanSuite" && (
                      <div className="content-leansuite w-100">
                        <img
                          src={lean}
                          alt={"lean"}
                          className="mb-4 d-block"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <div
                          className="title fs-4 mb-4 fw-bold w-100"
                          style={{
                            fontFamily: Font.basel,
                            color: Color.theme_first,
                          }}
                        >
                          LeanSuite 360
                        </div>
                        <ul className="list-group">
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/safety-improvement")}
                            to={"/safety-improvement"}
                          >
                            <CustomeSpan className="fw-5">
                              Safety Improvement
                            </CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/quality-improvement")}
                            to={"/quality-improvement"}
                          >
                            <CustomeSpan>Quality Improvement</CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/operational-excellence")}
                            to={"/operational-excellence"}
                          >
                            <CustomeSpan>Operational Excellence</CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/cost-reduction")}
                            to={"/cost-reduction"}
                          >
                            <CustomeSpan>Cost Reduction</CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/workforce-enablement")}
                            to={"/workforce-enablement"}
                          >
                            <CustomeSpan>Workforce Enablement</CustomeSpan>
                          </Link>
                        </ul>
                      </div>
                    )}
                    {activeMenuItem === "Idea" && (
                      <div className="content-idea w-100">
                        <img
                          src={idea}
                          alt={"idea"}
                          className="mb-4 d-block"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <div
                          className="title fs-4 mb-4 fw-bold w-100"
                          style={{
                            fontFamily: Font.basel,
                            color: Color.theme_first,
                          }}
                        >
                          Idea
                        </div>
                        <ul className="list-group">
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/suggestion-platform-for-hr")}
                            to={"/suggestion-platform-for-hr"}
                          >
                            <CustomeSpan className="fw-5">
                              Human Resources
                            </CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/suggestion-platform-for-operations")}
                            to={"/suggestion-platform-for-operations"}
                          >
                            <CustomeSpan>Operations</CustomeSpan>
                          </Link>
                        </ul>
                      </div>
                    )}

                    {activeMenuItem === "Failure" && (
                      <div className="content-failuretag w-100">
                        <img
                          src={failure}
                          alt={"failure"}
                          className="mb-4 d-block"
                          style={{ width: "40px", height: "40px" }}
                        />

                        <div
                          className="title fs-4 mb-4 fw-bold w-100"
                          style={{
                            fontFamily: Font.basel,
                            color: Color.theme_first,
                          }}
                        >
                          Failure Tag
                        </div>
                        <ul className="list-group">
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/issue-tracker-for-safety")}
                            to={"/issue-tracker-for-safety"}
                          >
                            <CustomeSpan className="fw-5">Safety</CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/issue-tracker-for-quality")}
                            to={"/issue-tracker-for-quality"}
                          >
                            <CustomeSpan>Quality</CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/theleansuite-partner-community")}
                            to={"/theleansuite-partner-community"}
                            style={{ display: "none" }}
                          >
                            <CustomeSpan>
                              5S/ Workplace Organization
                            </CustomeSpan>
                          </Link>
                          <Link
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            // onClick={() => handleNavigate("/issue-tracker-for-operations")}
                            to={"/issue-tracker-for-operations"}
                          >
                            <CustomeSpan>Professional Maintenance</CustomeSpan>
                          </Link>
                        </ul>
                      </div>
                    )}

                    {activeMenuItem === "Kaizen" && (
                      <div className="content-Kaizen w-100">
                        <img
                          src={failure}
                          alt={"failure"}
                          className="mb-4 d-block"
                          style={{ width: "40px", height: "40px" }}
                        />

                        <div
                          className="title fs-4 mb-4 fw-bold w-100"
                          style={{
                            fontFamily: Font.basel,
                            color: Color.theme_first,
                          }}
                        >
                          Kaizen
                        </div>
                        <ul className="list-group" style={{ display: "none" }}>
                          <li className="list-group-item border-0 mb-2 px-0 bg-transparent">
                            <CustomeSpan className="fw-5">Quick Win</CustomeSpan>
                          </li>
                          <li className="list-group-item border-0 mb-2 px-0 bg-transparent">
                            <CustomeSpan>PDCA</CustomeSpan>
                          </li>
                          <li className="list-group-item border-0 mb-2 px-0 bg-transparent">
                            <CustomeSpan>PDSA</CustomeSpan>
                          </li>
                          <li className="list-group-item border-0 mb-2 px-0 bg-transparent">
                            <CustomeSpan>8 Step Kaizen</CustomeSpan>
                          </li>
                          <li
                            className="list-group-item border-0 mb-2 px-0 bg-transparent"
                            onClick={() =>
                              handleNavigate(
                                "/continuous-improvement-for-operations"
                              )
                            }
                          >
                            <CustomeSpan>
                              7 Steps of Continuous Improvement
                            </CustomeSpan>
                          </li>
                          <li className="list-group-item border-0 mb-2 px-0 bg-transparent">
                            <CustomeSpan>A3 Project</CustomeSpan>
                          </li>
                          <li className="list-group-item border-0 mb-2 px-0 bg-transparent">
                            <CustomeSpan>Flow Automation</CustomeSpan>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {isMenuOpen === true && openProduct === "mainCompany" && (
          <div className="submenu-content h-100">
            <div className="back-container bg-white h-100"></div>
            <div className="container h-100">
              <div className="row h-100">
                <div
                  className="col-md-4 pt-40 bg-white"
                  onMouseEnter={() => handleMouseEnter("mainCompany")}
                  onMouseLeave={handleMouseLeave}
                >
                  <h1
                    className="fw-bold fs-36 bold-clr mb-4 pb-2"
                    style={{ fontFamily: Font.bold }}
                  >
                    Company
                  </h1>
                  <ul className="list-group">
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("/aboutus")}
                      to={"/aboutus"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">About Us</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      style={{ display: "none" }}
                      // onClick={() => handleNavigate("/careers")}
                      to={"/careers"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Careers</CustomeSpan>
                      </div>
                    </Link>
                    <Link className="list-group-item border-0 pb-2 px-4"
                      onClick={() => handleNavigate("/theleansuite-partner-community")}
                      to={"/theleansuite-partner-community"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Partner Network</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onClick={() => handleNavigate("/press")}
                      to={"/press"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Press</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      style={{ display: "none" }}
                      to={"404"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Customer Success</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      onClick={() => handleNavigate("/contact-us")}
                      to={"/contact-us"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Contact Us</CustomeSpan>
                      </div>
                    </Link>
                    <li
                      className="list-group-item border-0 pb-2 px-4"
                      style={{ display: "none" }}
                    >
                      <Seelink as={Link} to="/">
                        See all platform pricing
                      </Seelink>
                    </li>
                  </ul>
                </div>

                {/* {} */}
                <div
                  className="col-md-4 light-cream-clr pt-40 px-4 d-flex flex-wrap h-100"
                  style={{
                    background: Color.theme_four,
                    alignItems: activeMenuItem === null ? "center" : "start",
                  }}
                  onMouseEnter={() => handleMouseEnter("mainCompany")}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="content-wrapper-company m-auto">
                    <div
                      className="title fw-bold fs-36 bold-clr mb-4 w-100 text-center"
                      style={{
                        fontFamily: Font.bold,
                      }}
                    >
                      Our Story
                    </div>
                    <div className="description fs-6 text-center mb-4">
                      In 2021, LeanSuite was created to revolutionize the manufacturing industry by merging lean principles with emerging industrial software technologies. Our end-to-end software focuses on the 3 key aspects of manufacturing–People, Process, and Technology—for increased productivity and waste reduction.
                    </div>
                    <BigYellowLinkButton to={"/aboutus"} className="w-100">Learn about us</BigYellowLinkButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMenuOpen === true && openProduct === "mainLearnig" && (
          <div className="submenu-content h-100">
            <div className="back-container bg-white h-100"></div>
            <div className="container h-100">
              <div className="row h-100">
                <div
                  className="col-md-4 pt-40 bg-white"
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}

                  onMouseEnter={() => handleMouseEnter("mainLearnig")}
                  onMouseLeave={handleMouseLeave}
                >
                  <h1
                    className="fw-bold fs-36 bold-clr mb-4 pb-2"
                    style={{ fontFamily: Font.bold }}
                  >
                    Resources
                  </h1>
                  <ul className="list-group">
                    {/* <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"404"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Support</CustomeSpan>
                      </div>
                    </Link> */}
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"/resources/e-books"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">E-Books</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"/resources/templates"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Templates</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"/resources/videos"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Videos</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"/resources/webinars"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Webinars</CustomeSpan>
                      </div>
                    </Link>
                    <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("/blog")}
                      to={"/blog"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">Blogs</CustomeSpan>
                      </div>
                    </Link>
                    {/* <Link
                      className="list-group-item border-0 pb-2 px-4"
                      // onClick={() => handleNavigate("404")}
                      to={"404"}
                    >
                      <div className="icon-text">
                        <CustomeSpan className="fw-5">
                          Work Instructions
                        </CustomeSpan>
                      </div>
                    </Link> */}
                    <li
                      className="list-group-item border-0 pb-2 px-4"
                      style={{ display: "none" }}
                    >
                      <Seelink as={Link} to="/">
                        See all platform pricing
                      </Seelink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* -------------------------------Mobile Nav------------------------------- */}
      <Navbar
        expand="xl"
        className={`py-2 mobile-nav d-xl-none d-block px-0 ${isSticky ? 'sticky navbar-light' : 'navbar-dark'}`}
        style={{ backgroundColor: isSticky ? '#fff' : '#1c1c4b' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Container className="px-0">
          <div className="d-flex justify-content-between" style={{ paddingLeft: '.75rem', paddingRight: '.75rem', width: '100%' }}>
            <Navbar.Brand>
              <Link to={"/"}>
                <img src={logoSrc} alt="theleansuite logo" className="w-100" style={{ maxWidth: "145px" }} />
              </Link>
            </Navbar.Brand>
            <button
              id="mobile-menu-toggle"
              className="navbar-toggler border-0 shadow-none outline-0 p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={isMobileMenuOpen ? "true" : "false"}
              aria-label="Toggle navigation"
              onClick={handleToggle}
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              {isMobileMenuOpen ? (
                // <span className="fas fa-times"></span>
                <CloseButton variant="white" className="p-0" />
              ) : (
                <span className="navbar-toggler-icon"></span>
              )}
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <Navbar.Collapse className="show" id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown
                  title="Platform"
                  id="platform-dropdown"
                  className="d-xl-none d-block link-nav border-0 mx-0"
                >
                  <NavDropdown
                    title="LeanSuite 360"
                    id="LeanSuite-dropdown"
                    className="link-nav"
                  // onClick={() => handleNavigate("/what-is-leansuite")}
                  >
                    <NavDropdown.Item as={Link} to="/safety-improvement">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Safety Improvement
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/quality-improvement">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Quality Improvement
                      </span>
                    </NavDropdown.Item>

                    {/* <NavDropdown.Item to="/careers">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Quality Improvement 12
                      </span>
                    </NavDropdown.Item> */}

                    <NavDropdown.Item as={Link} to="/operational-excellence">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Operational Excellence
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cost-reduction">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Cost Reduction
                      </span>
                    </NavDropdown.Item>

                    <NavDropdown.Item as={Link} to="/workforce-enablement">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Workforce Enablement
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/*  */}
                  <NavDropdown
                    title="Idea"
                    id="LeanSuite-dropdown"
                    className="link-nav"
                  >
                    <NavDropdown.Item as={Link} to="404">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Human Resources
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="404">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Operations
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    title="Failure Tag"
                    id="LeanSuite-dropdown"
                    className="link-nav"
                  >
                    <NavDropdown.Item as={Link} to="/issue-tracker-for-safety">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Safety
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/issue-tracker-for-quality"
                    >
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Quality
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/theleansuite-partner-community"
                      style={{ display: "none" }}
                    >
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        5S/ Workplace Organization
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/issue-tracker-for-operations"
                    >
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Professional Maintenance
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>

                  {/* <NavDropdown
                    title="Kaizen"
                    id="LeanSuite-dropdown"
                    className="link-nav"
                  >
                    <NavDropdown.Item as={Link} to="/action/3.2">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Quick Win
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/action/3.2">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        PDCA
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/action/3.2">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        PDSA
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/action/3.2">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        8 Step Kaizen
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/continuous-improvement-for-operations"
                    >
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        7 Steps of Continuous Improvement
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/action/3.2">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        A3 Project
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/action/3.2">
                      <span
                        data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                      >
                        Flow Automation
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown> */}

                  <NavDropdown.Item as={Link} to="/continuous-improvement-for-operations">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Kaizen
                    </span>
                  </NavDropdown.Item>

                  <NavDropdown.Item as={Link} to="/competency-management">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Competencies
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/learning-management-system">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Training
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/loss-and-cost-management">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Loss and Cost Management
                    </span>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="404">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Cost Tree
                    </span>
                  </NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="/qa-matrix">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      QA Matrix
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cilr-management">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      CILR
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/gemba-walker">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Gemba Walker
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/rca">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Root Cause Analysis
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/job-cover-matrix">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Job Cover Matrix
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Company"
                  id="LeanSuite-dropdown"
                  className="d-xl-none d-block link-nav border-0 mx-0"
                >
                  <NavDropdown.Item as={Link} to="/aboutus">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      About Us
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/careers"
                    style={{ display: "none" }}
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Careers
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/theleansuite-partner-community"
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Partner Network
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/press">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Press
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/"
                    style={{ display: "none" }}
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Customer Success
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/contact-us">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Contact Us
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Resources"
                  id="LeanSuite-dropdown"
                  className="d-xl-none d-block link-nav border-0 mx-0"
                >
                  {/* <NavDropdown.Item as={Link} to="404">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Support
                    </span>
                  </NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="/resources/e-book">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      E-Books
                    </span>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/resources/templates">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Templates
                    </span>
                  </NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="/resources/video">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Videos
                    </span>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="/resources/webinars">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Webinars
                    </span>
                  </NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to="/blog">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Blogs
                    </span>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={Link} to="404">
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Work Instructions
                    </span>
                  </NavDropdown.Item> */}
                </NavDropdown>

                <Nav.Item>
                  <Nav.Link
                    eventKey="1"
                    as={Link}
                    to="/home"
                    className="link-nav mx-0"
                    style={{ display: "none" }}
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Success
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="1"
                    as={Link}
                    to="/pricing"
                    className="link-nav mx-0"
                  >
                    <span
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                    >
                      Pricing
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Link eventKey="1"
                  // as={Link} 
                  // to={"https://theleansuite.app/"} 
                  href={"https://starter.theleansuite.com/sign-up"}
                  className="link-nav mb-3 mx-0">
                  <span
                    data-bs-target="#navbarSupportedContent"
                    data-bs-toggle="collapse"
                  >
                    {/* Login */}
                    Start free now
                  </span>
                </Nav.Link>
              </Nav>
              {/* <div className="my-2">
                <Button className="text-decoration-none" onClick={bookDemo} to={"/schedule-demo"}>Schedule demo</Button>
                </div> */}
              {/* <Button2
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleNavigate("/404")}
                >
                  Watch Demo
                </Button2> */}
              <div className="my-3 px-3">
                <Button className="text-decoration-none rounded-5 d-block w-100 text-center" onClick={bookDemo} to={"/schedule-demo"}>Schedule demo</Button>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
export default NavbarComponent;
