import {
  CLEAR_ERRORS,
  GET_JOB_COVER_MATRIX_FAIL,
  GET_JOB_COVER_MATRIX_REQUEST,
  GET_JOB_COVER_MATRIX_SUCCESS,
} from "../constants/constants";

const initialState = {
  jobcovermatrix: [],
  isjobcovermatrix: false,
  error: null,
};

const jobcovermatrixReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_JOB_COVER_MATRIX_REQUEST:
      return { ...state, isjobcovermatrix: true };
    case GET_JOB_COVER_MATRIX_SUCCESS:
      return { ...state, isjobcovermatrix: false, jobcovermatrix: action.payload };
    case GET_JOB_COVER_MATRIX_FAIL:
      return { ...state, isjobcovermatrix: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default jobcovermatrixReducer;
