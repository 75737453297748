import axios from "axios";
import {
  GET_VIDEO_LANDING_DATA_FAIL,
  GET_VIDEO_LANDING_DATA_REQUEST,
  GET_VIDEO_LANDING_DATA_SUCCESS,
  url,
} from "../constants/constants";

export const videolandingPageAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_VIDEO_LANDING_DATA_REQUEST });

    const response = await axios.get(
      `${url}50805?_fields=acf&acf_format=standard`
    );
    dispatch({ type: GET_VIDEO_LANDING_DATA_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_VIDEO_LANDING_DATA_FAIL });
  }
};