import {
  CLEAR_ERRORS,
  GET_PRIVACY_FAIL,
  GET_PRIVACY_REQUEST,
  GET_PRIVACY_SUCCESS,
  GET_TERMS_FAIL,
  GET_TERMS_REQUEST,
  GET_TERMS_SUCCESS,
} from "../constants/constants";

const initialState = {
  privacyPage: [],
  isprivacyPage: false,
  termsPage: [],
  istermsPage: false,
  error: null,
};

const legalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRIVACY_REQUEST:
      return { ...state, isprivacyPage: true };
    case GET_PRIVACY_SUCCESS:
      return { ...state, isprivacyPage: false, privacyPage: action.payload };
    case GET_PRIVACY_FAIL:
      return { ...state, isprivacyPage: false, error: action.payload };

      case GET_TERMS_REQUEST:
        return { ...state, istermsPage: true };
      case GET_TERMS_SUCCESS:
        return { ...state, istermsPage: false, termsPage: action.payload };
      case GET_TERMS_FAIL:
        return { ...state, istermsPage: false, error: action.payload };
  
    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default legalReducer;
