import {
  GET_ISSUE_TRACKER_FAIL,
  GET_ISSUE_TRACKER_REQUEST,
  GET_ISSUE_TRACKER_SUCCESS,
  CLEAR_ERRORS,
  GET_OPERATION_REQUEST,
  GET_OPERATION_SUCCESS,
  GET_OPERATION_FAIL,
  GET_MANAGEMENT_QUALITY_REQUEST,
  GET_MANAGEMENT_QUALITY_SUCCESS,
  GET_MANAGEMENT_QUALITY_FAIL,
} from "../constants/constants";

const initialState = {
  issueTracker: [],
  isTracker: false,
  error: null,
  operationMaintenance: [],
  isOperationMaintenance: false,
  isOperationError: null,

  managementQuality: [],
  isManagementQuality: false,
  managementQualityError: null,
};

const issueTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ISSUE_TRACKER_REQUEST:
      return { ...state, isTracker: true };
    case GET_ISSUE_TRACKER_SUCCESS:
      return { ...state, isTracker: false, issueTracker: action.payload };
    case GET_ISSUE_TRACKER_FAIL:
      return { ...state, isTracker: false, error: action.payload };

    case GET_OPERATION_REQUEST:
      return { ...state, isOperationMaintenance: true };
    case GET_OPERATION_SUCCESS:
      return {
        ...state,
        isOperationMaintenance: false,
        operationMaintenance: action.payload,
      };
    case GET_OPERATION_FAIL:
      return {
        ...state,
        isOperationMaintenance: false,
        isOperationError: action.payload,
      };

    case GET_MANAGEMENT_QUALITY_REQUEST:
      return { ...state, isManagementQuality: true };
    case GET_MANAGEMENT_QUALITY_SUCCESS:
      return {
        ...state,
        isManagementQuality: false,
        managementQuality: action.payload,
      };
    case GET_MANAGEMENT_QUALITY_FAIL:
      return {
        ...state,
        isManagementQuality: false,
        managementQualityError: action.payload,
      };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default issueTrackerReducer;
