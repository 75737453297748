import {
  CLEAR_ERRORS,
  GET_AISUGGESTION_FAIL,
  GET_AISUGGESTION_REQUEST,
  GET_AISUGGESTION_SUCCESS,
} from "../constants/constants";

const initialState = {
  aisuggestion: [],
  isaisuggestion: false,
  error: null,
};

const AISuggestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_AISUGGESTION_REQUEST:
      return { ...state, isaisuggestion: true };
    case GET_AISUGGESTION_SUCCESS:
      return { ...state, isaisuggestion: false, aisuggestion: action.payload };
    case GET_AISUGGESTION_FAIL:
      return { ...state, isaisuggestion: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default AISuggestionReducer;
