import { useEffect, useState } from 'react';

function Announcement() {
    const [announcementEnabled, setAnnouncementEnabled] = useState(false);

    useEffect(() => {
        // Fetch the announcement setting from the WordPress API
        fetch('https://staging.theleansuite.com/backend/wp-json/custom/v1/announcement')
            .then(response => response.json())
            .then(data => setAnnouncementEnabled(data.enabled));
    }, []);

    return (
        <>
            {announcementEnabled && (
                <section className='announcementbanner-sec'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h1>Hello World</h1>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default Announcement;
