export const removeHtmlTags = (html) => {
  return html?.replace(/<\/?[^>]+(>|$)/g, "");
};

export const getName = (name) => {
  return name.replace(/-/g, " ");
};

export const formatString = (inputString) => {
  const reactFormat = inputString.toLowerCase().replace(/\s+/g, "-");
  return reactFormat;
};
