import {
  CLEAR_ERRORS,
  GET_PRICING_FAIL,
  GET_PRICING_REQUEST,
  GET_PRICING_SUCCESS,
} from "../constants/constants";

const initialState = {
  pricingContact: [],
  isPricing: false,
  error: null,
};

const pricingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICING_REQUEST:
      return { ...state, isPricing: true };
    case GET_PRICING_SUCCESS:
      return { ...state, isPricing: false, pricingContact: action.payload };
    case GET_PRICING_FAIL:
      return { ...state, isPricing: false, error: action.payload };
    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default pricingPageReducer;
