import {
  GET_QUALITY_IMPROVEMENT_FAIL,
  GET_QUALITY_IMPROVEMENT_REQUEST,
  GET_QUALITY_IMPROVEMENT_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/constants";

const initialState = {
    qualityimprovement: [],
    isqualityimprovement: false,
  error: null,
};

const qualityimprovementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUALITY_IMPROVEMENT_REQUEST:
      return { ...state, isqualityimprovement: true };
    case GET_QUALITY_IMPROVEMENT_SUCCESS:
      return {
        ...state,
        isqualityimprovement: false,
        qualityimprovement: action.payload,
      };
    case GET_QUALITY_IMPROVEMENT_FAIL:
      return { ...state, isqualityimprovement: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default qualityimprovementReducer;
