import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { Color } from "../../../theme/Color";
import { Font } from "../../../theme/Font";
import {
  BannerButton,
  Section80,
  SectionTitle,
  SubTitle,
} from "../../DesignComponent/DesignComponents";
import Dummy from "../../../assets/SuggestionOperations/dummy.webp";
import VideoPoster from "../../../assets/NewSite/video-poster-img_11zon_11zon_11zon.webp";
import { FaPlay, FaPause } from "react-icons/fa";

const Hero = () => {
  const Banner = styled.section`
    background: #1c1c4b;
    padding-top: 40px;
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 110px;
    }
  `;
  const VideoWrapper = styled.div`
    margin-top: -200px;
    position: relative;
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  `;
  const styles = {
    decorator: {
      height: "5px",
      width: "20px",
      background: "rgb(141, 142, 165)",
      borderRadius: "10px",
      position: "absolute",
    },
    decorator2: {
      height: "20px",
      width: "5px",
      background: "rgb(141, 142, 165)",
      borderRadius: "10px",
      position: "absolute",
      top: "1.5rem",
      left: "-2px",
    },
  };
  const vidRef = useRef();
  const btnRef = useRef();
  const [show, setShow] = useState(true);

  const playVideo = () => {
    if (vidRef.current) {
      if (show)
        vidRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      else vidRef.current.pause()
      setShow(!show);
    }
  };

  return (
    <>
      <Banner className="hero-banner text-white text-center d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <SubTitle className="sub-title text-uppercase mb-2">
                Video
              </SubTitle>
              <SectionTitle className="banner-title mb-3">
                Lorem ipsum dolor sit amet consectetur. Dis vel vel odio feugiat
                mattis metus porta odio.
              </SectionTitle>
              <BannerButton to={"/webinar-form"} className="px-5 mb-4">
                CTA
              </BannerButton>
            </div>
          </div>
        </div>
      </Banner>
      <Section80 className="img-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="video-top-position">
              <div
                style={{
                  ...styles.decorator,
                  top: "-2px",
                  left: "2rem",
                }}
              ></div>
              <div
                style={{
                  ...styles.decorator,
                  top: "-2px",
                  left: "3.6rem",
                }}
              ></div>
              <div
                style={{
                  ...styles.decorator2,
                }}
              ></div>
              <div
                className="video-wrapper h-100 shadow d-flex justify-content-center align-items-center"
                style={{
                  border: "6px solid #8D8EA5",
                  borderRadius: "21px",
                  background: "#8D8EA5",
                  position: "relative",
                }}
              >
                <button
                  ref={btnRef}
                  style={{
                    position: "absolute",
                    zIndex: 999,
                    backgroundColor: Color.white,
                    boxShadow: "0 0 5px 0 #eee",
                    padding: "15px 30px",
                    borderRadius: "30px"
                  }}
                  className={`btn ${show ? 'btn-play' : 'btn-pause'}`}
                  type="button"
                  onClick={playVideo}
                >
                  {show ? (<FaPlay style={{ fontSize: "24px" }} />) : <FaPause style={{ fontSize: "24px" }} />}


                </button>
                <div
                  className="rounded-4 h-100 w-100"
                  style={{
                    border: "10px solid #8D8EA5",
                  }}
                >
                  <video
                    className="w-100 h-100 rounded-4"
                    ref={vidRef}
                    loop
                    muted
                    preload="metadata"
                    // poster={VideoPoster}
                    style={{ objectFit: "cover" }}c
                    loading="lazy"
                    fetchpriority="low"
                  >
                    <source
                      src={`https://staging.theleansuite.com/backend/wp-content/uploads/2024/06/home-page-video-continuous-improvement-process-0.9.webm`}
                      type="video/webm"
                    ></source>
                    <source
                      src={`https://staging.theleansuite.com/backend/wp-content/uploads/2024/06/home-page-video-continuous-improvement-process-0.9.mp4`}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </Section80>
    </>
  );
};

export default Hero;
