import {
  CLEAR_ERRORS,
  GET_PARTNERS_FAIL,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_SUCCESS,
} from "../constants/constants";

const initialState = {
  partnersPage: [],
  ispartnersPage: false,
  error: null,
};

const partnersPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_PARTNERS_REQUEST:
      return { ...state, ispartnersPage: true };
    case GET_PARTNERS_SUCCESS:
      return { ...state, ispartnersPage: false, partnersPage: action.payload };
    case GET_PARTNERS_FAIL:
      return { ...state, ispartnersPage: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default partnersPageReducer;
