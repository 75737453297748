import React from "react";
import { Color } from "../../theme/Color";
import { BannerButton, Description, Section, SectionTitle, SubTitle } from "../DesignComponent/DesignComponents";
const Hero = ({ data }) => {
  return (
    <>
      <Section className="d-flex align-items-md-center align-item-start text-white" style={{ backgroundColor: Color.theme_first }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 mb-md-0 mb-3">
              {data?.text_before_title && (
                <SubTitle className="sub-title mb-2">{data?.text_before_title}</SubTitle>
              )}
              {data?.title && (
                <SectionTitle className="mb-3">{data?.title}</SectionTitle>
              )}
              {data?.description && (
                <Description className="description mb-4" style={{ color: Color.lightwhite }}>
                  {data?.description}
                </Description>
              )}
              {data?.call_to_action_button && (
                <BannerButton to={data?.call_to_action_button?.url}>{data?.call_to_action_button?.title}</BannerButton>
              )}
            </div>
            <div className="offset-md-1 col-md-6 text-md-end text-start">
              {data?.image && (
                <img src={data?.image} alt={data?.title} className="w-100" fetchpriority="high" />
              )}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Hero;
