import React from "react";
import { Color } from "../../theme/Color";
import { BigYellowLinkButton, BigWhiteButton, Description, Section80, TitleSection } from "../DesignComponent/DesignComponents";

const Info = ({ title, desc, btn, btn2, bggrey, btnlink, btn2link, nocapitalize }) => {
  return (
    <>
      <Section80
        className="movefaster-sec text-center text-white"
        style={{ backgroundColor: bggrey ? Color.beige : Color.theme_secound }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {title ? (
                <TitleSection className="mb-3" style={{ color: bggrey ? Color.darkBlack : Color.white }}>{title}</TitleSection>
              ) : (<TitleSection className="mb-3" style={{ color: bggrey ? Color.darkBlack : Color.white }}>{"Ready to digitally transform your operations with LeanSuite?"}</TitleSection>
              )}
              {desc ? (
                <Description className="description mb-4"
                  style={{
                    color: bggrey ? Color.lighterGrey : Color.lightwhite,
                  }}
                >{desc}</Description>
              ) : (
                <Description className="description mb-4" style={{ color: bggrey ? Color.lighterGrey : Color.lightwhite }}>{"See how digitizing your frontline processes with LeanSuite's Lean Manufacturing Software can help you optimize core processes in your company"}</Description>
              )}
              <div className="d-grid">
                {/* { bggrey ? (
                <> */}
                <BigYellowLinkButton to={btnlink ? `${btnlink}` : `/schedule-demo`} className="fw-bold mx-auto mb-3"
                  style={{
                    color: Color.theme_first,
                    textTransform: nocapitalize ? "none" : "capitalize"
                  }}
                >
                  {btn || "Schedule Demo"}
                </BigYellowLinkButton>
                {btn2 ? (
                  <BigWhiteButton to={btn2link ? `${btn2link}` : `/`} className={`fw-bold mx-auto ${bggrey ? "" : "text-white"}`}
                    style={{
                      color: Color.theme_first,
                      textTransform: nocapitalize ? "none" : "capitalize"
                    }}
                  >
                    {btn2 || "Watch Tour Video"}
                  </BigWhiteButton>
                ) : (
                  <></>
                )}
                {/* </>
              ) : (
                <> */}
                {/* <BigYellowLinkButton to={"/schedule-demo"} className="fw-bold mx-auto mb-3">{"Request Demo"}</BigYellowLinkButton>
              <BigWhiteButton to={"#"} className="fw-bold mx-auto">{"Watch Tour Video"}</BigWhiteButton> */}
                {/* </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </Section80>
    </>
  );
};
export default Info;
