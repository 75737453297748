import {
  GET_BLOGS_LIST_FAIL,
  GET_BLOGS_LIST_REQUEST,
  GET_BLOGS_LIST_SUCCESS,
  CLEAR_ERRORS,
  GET_SINGLE_BLOGS_LIST_REQUEST,
  GET_SINGLE_BLOGS_LIST_SUCCESS,
  GET_SINGLE_BLOGS_LIST_FAIL,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_SINGLE_CATEGORY_LIST_FAIL,
  GET_SINGLE_CATEGORY_LIST_REQUEST,
  GET_SINGLE_CATEGORY_LIST_SUCCESS,
  GET_ALL_RESOURCES_LIST_FAIL,
  GET_ALL_RESOURCES_LIST_SUCCESS,
  GET_ALL_RESOURCES_LIST_REQUEST,
  GET_ALL_RESOURCES_POST_FAIL,
  GET_ALL_RESOURCES_POST_SUCCESS,
  GET_ALL_RESOURCES_POST_REQUEST,
  GET_SINGLE_RESOURCES_LIST_SUCCESS,
  GET_SINGLE_RESOURCES_LIST_FAIL,
  GET_SINGLE_RESOURCES_LIST_REQUEST,
  
} from "../constants/constants";

const initialState = {
  isBlogLoading: false,
  isSinglBlog: false,
  isCategory: false,
  isPress: false,
  blogsList: [],
  categoryList: [],
  singleBlogs: [],
  pressBlogs: [],
  resourcesList: [],
  isresourcesList: [],
  resourcesBlogList: [],
  isresourcesBlogList: [],
  issingleResources: false,
  singleResources: [],
  error: null,
};

export const blogListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOGS_LIST_REQUEST:
      return { ...state, isBlogLoading: true };
    case GET_BLOGS_LIST_SUCCESS:
      return { ...state, isBlogLoading: false, blogsList: action.payload };
    case GET_BLOGS_LIST_FAIL:
      return { ...state, isBlogLoading: false, error: action.payload };

    case GET_SINGLE_BLOGS_LIST_REQUEST:
      return { ...state, isSinglBlog: true };
    case GET_SINGLE_BLOGS_LIST_SUCCESS:
      return { ...state, isSinglBlog: false, singleBlogs: action.payload };
    case GET_SINGLE_BLOGS_LIST_FAIL:
      return { ...state, isSinglBlog: false, error: action.payload };

    case GET_CATEGORY_REQUEST:
      return { ...state, isCategory: true };
    case GET_CATEGORY_SUCCESS:
      return { ...state, isCategory: false, categoryList: action.payload };
    case GET_CATEGORY_FAIL:
      return { ...state, isCategory: false, error: action.payload };

    case GET_SINGLE_CATEGORY_LIST_REQUEST:
      return { ...state, isPress: true };
    case GET_SINGLE_CATEGORY_LIST_SUCCESS:
      return { ...state, isPress: false, pressBlogs: action.payload };
    case GET_SINGLE_CATEGORY_LIST_FAIL:
      return { ...state, isPress: false, error: action.payload };

      case GET_ALL_RESOURCES_LIST_REQUEST:
      return { ...state, isresourcesList: true };
    case GET_ALL_RESOURCES_LIST_SUCCESS:
      return { ...state, isresourcesList: false, resourcesList: action.payload };
    case GET_ALL_RESOURCES_LIST_FAIL:
      return { ...state, isresourcesList: false, error: action.payload };

      case GET_ALL_RESOURCES_POST_REQUEST:
        return { ...state, isresourcesBlogList: true };
      case GET_ALL_RESOURCES_POST_SUCCESS:
        return { ...state, isresourcesBlogList: false, resourcesBlogList: action.payload };
      case GET_ALL_RESOURCES_POST_FAIL:
        return { ...state, isresourcesBlogList: false, error: action.payload };

        case GET_SINGLE_RESOURCES_LIST_REQUEST:
          return { ...state, issingleResources: true };
        case GET_SINGLE_RESOURCES_LIST_SUCCESS:
          return { ...state, issingleResources: false, singleResources: action.payload };
        case GET_SINGLE_RESOURCES_LIST_FAIL:
          return { ...state, issingleResources: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};
