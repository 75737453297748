import axios from "axios";
import {
  GET_BLOGS_LIST_FAIL,
  GET_BLOGS_LIST_REQUEST,
  GET_BLOGS_LIST_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_SINGLE_BLOGS_LIST_FAIL,
  GET_SINGLE_BLOGS_LIST_REQUEST,
  GET_SINGLE_BLOGS_LIST_SUCCESS,
  GET_SINGLE_CATEGORY_LIST_FAIL,
  GET_SINGLE_CATEGORY_LIST_REQUEST,
  GET_SINGLE_CATEGORY_LIST_SUCCESS,
  GET_ALL_RESOURCES_LIST_FAIL,
  GET_ALL_RESOURCES_LIST_SUCCESS,
  GET_ALL_RESOURCES_LIST_REQUEST,
  GET_ALL_RESOURCES_POST_FAIL,
  GET_ALL_RESOURCES_POST_SUCCESS,
  GET_ALL_RESOURCES_POST_REQUEST,
  GET_SINGLE_RESOURCES_LIST_SUCCESS,
  GET_SINGLE_RESOURCES_LIST_FAIL,
  GET_SINGLE_RESOURCES_LIST_REQUEST,
  API_URL,
} from "../constants/constants";

export const getAllBlogsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BLOGS_LIST_REQUEST });

    const response = await axios.get(
      `${API_URL}custom-api/v1/all-posts/`
    );

    dispatch({ type: GET_BLOGS_LIST_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_BLOGS_LIST_FAIL, error: error });
  }
};
export const getSingleBlog = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_BLOGS_LIST_REQUEST });

    const response = await axios.get(
      `${API_URL}custom-api/v1/post/${id}`
    );

    // console.log("response", response);

    dispatch({ type: GET_SINGLE_BLOGS_LIST_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_SINGLE_BLOGS_LIST_FAIL, error: error });
  }
};
export const getCategoryAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORY_REQUEST });

    const response = await axios.get(
      `${API_URL}custom-api/v1/all-categories-posts/`
    );

    dispatch({ type: GET_CATEGORY_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_CATEGORY_FAIL, error: error });
  }
};
export const getSingleCategoryBlogs = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_CATEGORY_LIST_REQUEST });

    const response = await axios.get(
      `${API_URL}custom-api/v1/category-posts/1615`
    );

    // console.log("response", response);

    dispatch({ type: GET_SINGLE_CATEGORY_LIST_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_SINGLE_CATEGORY_LIST_FAIL, error: error });
  }
};

export const getAllResources = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_RESOURCES_LIST_REQUEST });

    const response = await axios.get(
      `${API_URL}custom-api/v1/resources-categories`
    );

    // console.log("resources-categories", response);

    dispatch({ type: GET_ALL_RESOURCES_LIST_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_ALL_RESOURCES_LIST_FAIL, error: error });
  }
};

export const getAllResourcesBlogs = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_RESOURCES_POST_REQUEST });

    const response = await axios.get(
      `${API_URL}custom/v1/resources-posts`
    );

    // console.log("response", response);

    dispatch({ type: GET_ALL_RESOURCES_POST_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_ALL_RESOURCES_POST_FAIL, error: error });
  }
};
export const getSingleResource = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_RESOURCES_LIST_REQUEST });

    const response = await axios.get(
      `${API_URL}custom-api/v1/resource/${id}`
    );

    // console.log("response", response);

    dispatch({ type: GET_SINGLE_RESOURCES_LIST_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_SINGLE_RESOURCES_LIST_FAIL, error: error });
  }
};