import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Color } from "../../../theme/Color";
import { Font } from "../../../theme/Font";
import DataItem from "../../CommonComponent/DataItem";

const RecentBlog = ({ blog }) => {
  const [displayCount] = useState(3);
  const [allBlogsList, setAllBlogsList] = useState([]);

  useEffect(() => {
    const shuffledBlog = [...blog].sort(() => Math.random() - 0.5);
    setAllBlogsList(shuffledBlog.slice(0, displayCount));
  }, [displayCount, blog]);

  const handleScroll = () => {
    window.scroll(0, 0);
  };

  return (
    <>
      <Section
        className="recent-posts"
        style={{ backgroundColor: Color.theme_four }}
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 d-flex flex-nowrap justify-content-between">
              <SmallTitle className="w-auto">Explore More</SmallTitle>
            </div>
            <div className="col-md-12">
              <hr className="m-0" />
            </div>
          </div>
          <div className="row">
            {allBlogsList.length ? (
              allBlogsList?.map((data, i) => (
                <DataItem
                  key={i}
                  imageLink={data?.featured_image}
                  categoryNames={data?.categories}
                  title={data?.title}
                  excerpt={data?.excerpt}
                  slug={data?.slug}
                  handleScroll={handleScroll}
                />
              ))
            ) : (
              <p className="text-center fs-5">No Data found</p>
            )}
          </div>
        </div>
      </Section>
    </>
  );
};

export default RecentBlog;

const Section = styled.section`
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const SmallTitle = styled.p`
  font-family: ${Font.bold};
`;
