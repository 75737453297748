import {
  CLEAR_ERRORS,
  GET_SAFETY_IMPROVEMENT_FAIL,
  GET_SAFETY_IMPROVEMENT_REQUEST,
  GET_SAFETY_IMPROVEMENT_SUCCESS,
} from "../constants/constants";

const initialState = {
  safetyimprovement: [],
  issafetyimprovement: false,
  error: null,
};

const safetyimprovementReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_SAFETY_IMPROVEMENT_REQUEST:
      return { ...state, issafetyimprovement: true };
    case GET_SAFETY_IMPROVEMENT_SUCCESS:
      return { ...state, issafetyimprovement: false, safetyimprovement: action.payload };
    case GET_SAFETY_IMPROVEMENT_FAIL:
      return { ...state, issafetyimprovement: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default safetyimprovementReducer;
