import {
  CLEAR_ERRORS,
  GET_PRESS_FAIL,
  GET_PRESS_REQUEST,
  GET_PRESS_SUCCESS,
  GET_PRESS_LIST_FAIL,
  GET_PRESS_LIST_REQUEST,
  GET_PRESS_LIST_SUCCESS,
} from "../constants/constants";

const initialState = {
  Press: [],
  ispress: false,
  pressList: [],
  ispressList: false,
  error: null,
};

const pressPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESS_REQUEST:
      return { ...state, ispress: true };
    case GET_PRESS_SUCCESS:
      return { ...state, ispress: false, Press: action.payload };
    case GET_PRESS_FAIL:
      return { ...state, ispress: false, error: action.payload };

    case GET_PRESS_LIST_REQUEST:
      return { ...state, ispressList: true };
    case GET_PRESS_LIST_SUCCESS:
      return { ...state, ispressList: false, pressList: action.payload };
    case GET_PRESS_LIST_FAIL:
      return { ...state, ispressList: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default pressPageReducer;
