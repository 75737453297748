import {
  CLEAR_ERRORS,
  GET_CAREERS_FAIL,
  GET_CAREERS_REQUEST,
  GET_CAREERS_SUCCESS,
  GET_VIEW_OPP_FAIL,
  GET_VIEW_OPP_REQUEST,
  GET_VIEW_OPP_SUCCESS,
} from "../constants/constants";

const initialState = {
  careersPage: [],
  iscareersPage: false,
  viewoppPage: [],
  isviewoppPage: false,
  error: null,
};

const careersPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAREERS_REQUEST:
      return { ...state, iscareersPage: true };
    case GET_CAREERS_SUCCESS:
      return { ...state, iscareersPage: false, careersPage: action.payload };
    case GET_CAREERS_FAIL:
      return { ...state, iscareersPage: false, error: action.payload };

      case GET_VIEW_OPP_REQUEST:
      return { ...state, isviewoppPage: true };
    case GET_VIEW_OPP_SUCCESS:
      return { ...state, isviewoppPage: false, viewoppPage: action.payload };
    case GET_VIEW_OPP_FAIL:
      return { ...state, isviewoppPage: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default careersPageReducer;
