import {
  CLEAR_ERRORS,
  GET_CONTACT_FAIL,
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
} from "../constants/constants";

const initialState = {
  contactPage: [],
  iscontactPage: false,
  error: null,
};

const contactPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_REQUEST:
      return { ...state, iscontactPage: true };
    case GET_CONTACT_SUCCESS:
      return { ...state, iscontactPage: false, contactPage: action.payload };
    case GET_CONTACT_FAIL:
      return { ...state, iscontactPage: false, error: action.payload };
    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default contactPageReducer;
