import {
  CLEAR_ERRORS,
  GET_LMS_FAIL,
  GET_LMS_REQUEST,
  GET_LMS_SUCCESS,
} from "../constants/constants";

const initialState = {
  lmsPage: [],
  islmsPage: false,
  error: null,
};

const lmsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LMS_REQUEST:
      return { ...state, islmsPage: true };
    case GET_LMS_SUCCESS:
      return { ...state, islmsPage: false, lmsPage: action.payload };
    case GET_LMS_FAIL:
      return { ...state, islmsPage: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default lmsPageReducer;
