import {
  CLEAR_ERRORS,
  GET_LOSS_COST_FAIL,
  GET_LOSS_COST_REQUEST,
  GET_LOSS_COST_SUCCESS,
} from "../constants/constants";

const initialState = {
  lossandcost: [],
  islossandcost: false,
  error: null,
};

const lossandcostReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_LOSS_COST_REQUEST:
      return { ...state, islossandcost: true };
    case GET_LOSS_COST_SUCCESS:
      return { ...state, islossandcost: false, lossandcost: action.payload };
    case GET_LOSS_COST_FAIL:
      return { ...state, islossandcost: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default lossandcostReducer;
