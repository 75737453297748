import {
  GET_SUGGESTION_OPERATION_FAIL,
  GET_SUGGESTION_OPERATION_REQUEST,
  GET_SUGGESTION_OPERATION_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/constants";

const initialState = {
  suggestionOperation: [],
  isSuggestionOperation: false,
  error: null,
};

const suggestionOperationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTION_OPERATION_REQUEST:
      return { ...state, isSuggestionOperation: true };
    case GET_SUGGESTION_OPERATION_SUCCESS:
      return {
        ...state,
        isSuggestionOperation: false,
        suggestionOperation: action.payload,
      };
    case GET_SUGGESTION_OPERATION_FAIL:
      return { ...state, isSuggestionOperation: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default suggestionOperationReducer;
