export const Font = {
  bold: "StyreneBold",
  light: "StyreneLight",
  lightItalic: "StyreneLightItalic",
  medium: "StyreneMedium",
  mediumItalic: "StyreneMediumItalic",
  regular: "StyreneRegular",
  regularItalic: "StyreneRegularItalic",
  thin: "StyreneThin",
  thinItalic: "StyreneThinItalic",
  basel: "Basel",
  baselbold: "BaselBold",
  trial: "Styrene B Trial",
};
