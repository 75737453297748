import {
  CLEAR_ERRORS,
  GET_CILR_FAIL,
  GET_CILR_REQUEST,
  GET_CILR_SUCCESS,
} from "../constants/constants";

const initialState = {
  cilr: [],
  iscilr: false,
  error: null,
};

const cilrReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_CILR_REQUEST:
      return { ...state, iscilr: true };
    case GET_CILR_SUCCESS:
      return { ...state, iscilr: false, cilr: action.payload };
    case GET_CILR_FAIL:
      return { ...state, iscilr: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default cilrReducer;
