import {
  GET_ATTACHMENT_REQUEST,
  GET_ATTACHMENT_SUCCESS,
  GET_ATTACHMENT_FAIL,
  CLEAR_ERRORS,
} from "../constants/constants";

const initialState = {
  attachment: [],
  isAttachment: false,
  error: null,
};

const attachmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTACHMENT_REQUEST:
      return { ...state, isAttachment: true };
    case GET_ATTACHMENT_SUCCESS:
      return { ...state, isAttachment: false, attachment: action.payload };
    case GET_ATTACHMENT_FAIL:
      return { ...state, isAttachment: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default attachmentReducer;
