import axios from "axios";
import {
  GET_AIQAMATRIX_FAIL,
  GET_AIQAMATRIX_REQUEST,
  GET_AIQAMATRIX_SUCCESS,
  url,
} from "../constants/constants";

export const AIQAmatrixAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_AIQAMATRIX_REQUEST });

    const response = await axios.get(
      `${url}50746?_fields=acf&acf_format=standard`
    );
    console.log(response);

    dispatch({ type: GET_AIQAMATRIX_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({ type: GET_AIQAMATRIX_FAIL, error });
  }
};
