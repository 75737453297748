import {
  CLEAR_ERRORS,
  GET_CAMPAIGN_REQUEST,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAIL,
} from "../constants/constants";

const initialState = {
  campaign: [],
  isCampaign: false,
  error: null,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_REQUEST:
      return { ...state, isCampaign: true };
    case GET_CAMPAIGN_SUCCESS:
      return { ...state, isCampaign: false, campaign: action.payload };
    case GET_CAMPAIGN_FAIL:
      return { ...state, isCampaign: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default campaignReducer;
