import React from "react";
import { Button } from "react-bootstrap";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { styled } from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import star from "../../assets/NewSite/rating-star.webp";
import { Color } from "../../theme/Color";
import { Font } from "../../theme/Font";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Section112, SubTitle, TitleSection } from "../DesignComponent/DesignComponents";
import { BsStarFill } from "react-icons/bs";

const TestimonialV2 = (props) => {
  const data = props && props?.data;
  // console.log(data);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 425, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 25,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
     return (
      <div className="carousel-button-group position-absolute w-auto">
        <div className="slider-btns carrousal d-flex w-100 justify-content-md-end">
          <Button className={"carousel-control-prev mx-0"} onClick={() => previous()} aria-label="previous">
            <BsArrowLeftCircle
              fontSize={"120px"}
              style={{ color: "#C3C9D5" }}
            />
          </Button>
          <Button className="carousel-control-next mx-2" onClick={() => next()} aria-label="next">
            <BsArrowRightCircle
              fontSize={"120px"}
              style={{ color: "#C3C9D5" }}
            />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Section112 className="testislider-sec overflow-hidden">
        <div className="container">
          <div className="row mb-5 pb-md-5">
            <div className="col-md-12">
              <SubTitle className="sub-title mb-2">{data?.text_before_title}</SubTitle>
              <TitleSection className="mb-4 pb-2">{data?.title}</TitleSection>
              <div className="trustradius-sec d-flex justify-content-between flex-md-nowrap flex-wrap ">
                <div className="logo-wrapper w-100 mb-md-2 mb-4">
                  <SmallText className="mb-2">
                    {data?.trustradius?.sub_title}
                  </SmallText>
                  <img
                    src={data?.trustradius?.logo}
                    alt={"trustradius"}
                    className="w-100"
                    style={{ maxWidth: "136px" }}
                    fetchpriority="low"
                    loading="lazy"
                  />
                </div>
              </div>
              <hr className="mt-4" style={{ borderColor: "C3C9D5" }} />
            </div>
          </div>
          <div className="row position-relative">
            <Carousel
              // swipeable={true}
              // draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={6000}
              keyBoardControl={true}
              transitionDuration={500}
              containerClass="carousel-container"
              deviceType={props.deviceType}
              itemClass="carousel-item-padding-40-px"
              partialVisibilityGutter={true}
              partialVisible={true}
              // centerMode={props.deviceType === "mobile" ? false : true}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              {data &&
                data?.testimonials?.map((item, index) => (
                  <div key={index} className="h-100">
                    <div
                      className="item p-4 h-100"
                      style={{
                        backgroundColor: Color.lightYellow,
                        margin: "0 1rem",
                      }}
                    >
                      <SliderTitle className="mb-3">
                        {item?.testimonial_slider?.title}
                      </SliderTitle>
                      {item?.testimonial_slider?.rating_number ? (
                        <div className="mb-3">
                          {Array.from({ length: 5 }, (_, i) => (
                            <span key={i}>
                              {i < parseInt(item?.testimonial_slider?.rating_number) ? (
                                <BsStarFill className="me-1" style={{ color: Color.yelow }} />
                              ) : (
                                <BsStarFill className="me-1" style={{ color: "#B4B4B4" }} />
                              )}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <img
                          src={star}
                          alt={"star"}
                          className="mb-3"
                          style={{ width: "120px", height: "24px" }}
                          fetchpriority="low"
                          loading="lazy"
                        />
                      )}
                      <Description className="mb-4">
                        <q>{item?.testimonial_slider?.testimonial_content}</q>
                      </Description>
                      <Date className="mb-0">
                        {item?.testimonial_slider?.date}
                      </Date>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </Section112>
    </>
  );
};
export default TestimonialV2;

const SmallText = styled.p`
  color: ${Color.lightGreen};
  font-family: ${Font.basel};
  font-size: 14px;
`;
const SliderTitle = styled.h3`
  font-size: 21px;
  font-family:${Font.basel},
  font-weight:500;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;
const Description = styled.p`
  font-size: 16px;
  font-family: ${Font.basel};
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
const Date = styled.p`
  font-size: 18px;
  color: ${Color.lightBrown};
  font-family: ${Font.basel};
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

