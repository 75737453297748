// export const scroll = () => {
//   return window.scrollTo(0, 0);

// };
import { trackPageVisit } from "./pageTracker";
export const scroll = () => {
  trackPageVisit();
  return window.scrollTo({
    top: 0,
    behavior: "instant",
  });
};
