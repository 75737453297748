// src/ModalComponent.js
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { SubmitButton } from '../DesignComponent/DesignComponents';
import { InlineWidget } from 'react-calendly';
import axios from 'axios';
import popupImage from '../../assets/NewSite/popup.svg'

// Construct Calendly URL with pre-filled name and email
const calendlyUrl = new URL("https://calendly.com/angadsingh63/30min-1");

const ModalComponent = ({ show, handleClose }) => {
  const [ipAddress, setIPAddress] = useState('')
  const [sheetData, setSheetData] = useState([])
  const [error, setError] = useState('')
  const [showNext, setShowNext] = useState(false)
  const [otherReason, setOtherReason] = useState('')
  const [isotherReason, setIsOtherReason] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')
  const feedbackText = [
    "I'm interested, but need more info",
    "I'm not right person for this",
    "We're not focused on this right now",
    "Our team is already using the solution",
    "Other Reason"
  ]

  const getSpreadsheetData = async () => {
    try {
      let response = await axios.get('https://theleansuite.com/api/sheet-data');
      setSheetData(response.data.data)
    } catch (error) {
      // console.error('Error adding row:', error);
    }
  }

  const appendSpreadsheet = async (newRow) => {
    try {
      let response = await axios.post('https://theleansuite.com/api/add-row', newRow);
      if (response.statusText === 'OK') {
        await getSpreadsheetData()
        // alert('Row added successfully!');
      }
    } catch (error) {
      console.error('Error adding row:', error);
      // alert('Error adding row');
    }
  };

  const handleUserIP = async () => {
    let response = await axios.get('https://api.ipify.org?format=json');
    setIPAddress(response.data.ip)
    // let isExist = sheetData.findIndex(data => data.includes(response.data.ip))
    // if (isExist === -1) {
    //   const newRow = { IP_Address: response.data.ip, Count: 1, Reason: selectedReason };
    //   await appendSpreadsheet(newRow)
    // } else {
    //   setError('User exist..!!')
    // }
    handleClose();
    setShowNext(true)
  }

  const getIpAdd = async () => {
    let response = await axios.get('https://api.ipify.org?format=json');
    setIPAddress(response.data.ip)
  }

  useEffect(async () => {
    await getIpAdd()
    await getSpreadsheetData();
  }, [])

  useEffect(() => {
  }, [sheetData])

  const handleReasons = (btnText) => {
    setSelectedReason(btnText)
    if (btnText.includes('Other Reason')) setIsOtherReason(true)
    else {
      setIsOtherReason(false)
      handleConfirm(btnText);
    }

  }

  const handleConfirm = async (btnText) => {
    let isExist = sheetData?.findIndex(data => data.includes(ipAddress))
    if (isExist == -1 || isExist == undefined) {
      const newRow = { IP_Address: ipAddress, Count: 1, Reason: isotherReason ? `${btnText} - ${otherReason}` : btnText };
      await appendSpreadsheet(newRow)
    } else {
      setShowNext(false)
      setError('User exist..!!')
    }
  }

  return (
    <>
      <Modal backdrop="static" show={show} onHide={handleClose} centered size="xl" style={{ zIndex: 99999, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        <Modal.Header closeButton className='border-0 pb-0 text-white' style={{ backgroundColor: '#1C1C4B' }}>
          <Modal.Title className='text-center w-100 fw-bold'>Need Some Help!</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 border-0 text-white' style={{ backgroundColor: '#1C1C4B' }}>
          <p className='text-center'>Send me your question or schedule a call for us to talk.</p>

          <InlineWidget
            // url="https://calendly.com/angadsingh63/30min-1"
            url={calendlyUrl.toString()}
            styles={{
              height: '600px',
              marginTop: '0px'
            }}
            pageSettings={{
              backgroundColor: 'ffffff',
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: '272768',
              textColor: '292929'
            }}
          />
        </Modal.Body>
        <Modal.Footer className='border-0 justify-content-center text-white' style={{ backgroundColor: '#1C1C4B' }}>
          <div className='d-grid'>
            <SubmitButton className="p-3 border-0 w-auto mb-2" type="button" onClick={handleUserIP}>
              Remind Me Later
            </SubmitButton>
            <button style={{ backgroundColor: 'rgb(28, 28, 75)', color: '#fff' }} className="p-3 border-0 w-auto mb-2" type="button" onClick={handleClose}>
              Not Required For Our Operations
            </button>
            <p className='text-danger'>{error}</p>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" show={showNext} onHide={() => setShowNext(false)} centered size="lg" style={{ zIndex: 99999, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        <Modal.Body className='border-0 text-white p-5' style={{ backgroundColor: '#1C1C4B' }}>
          <div className='row py-5 align-items-center'>
            <div className='col-lg-5'>
              <h3>
                Your Feedback Matters!
              </h3>
              <p className='text-light'>
                Not ready right now? No problem! Help us understand where you stand by selecting the option that best describes your situation.
              </p>
              <div style={{
                height: '225px',
                width: '225px',
                margin: '0 auto'
              }}>
                <img className='h-100 w-100' src={popupImage} alt='popup image' />
              </div>

            </div>
            <div className='col-lg-6 border-start border-secondary ms-4 ps-5'>
              {feedbackText.map(text =>
                <button className={`p-3 w-100 mb-2 btn ${selectedReason === text ? 'btn-warning' : 'btn-outline-warning'}`} type="button" onClick={() => handleReasons(text)}>
                  {text}
                </button>
              )}
              {isotherReason && <><div className='mt-2'>
                <textarea
                  name="reason"
                  rows={3}
                  className='w-100 mb-2'
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              </div>
                <button className="p-3 w-100 mb-2 btn btn-warning" type="button" onClick={() => handleConfirm('Other Reason')}>
                  Confirm
                </button></>
              }
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
