import {
  CLEAR_ERRORS,
  GET_VIDEO_LANDING_DATA_FAIL,
  GET_VIDEO_LANDING_DATA_REQUEST,
  GET_VIDEO_LANDING_DATA_SUCCESS,
} from "../constants/constants";

const initialState = {
  videolanding: [],
  isvideolanding: false,
  error: null,
};

const videolandingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_VIDEO_LANDING_DATA_REQUEST:
      return { ...state, isvideolanding: true };
    case GET_VIDEO_LANDING_DATA_SUCCESS:
      return { ...state, isvideolanding: false, videolanding: action.payload };
    case GET_VIDEO_LANDING_DATA_FAIL:
      return { ...state, isvideolanding: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default videolandingPageReducer;
