import React from "react";
import styled from "styled-components";
import { Color } from "../../theme/Color";
import { Description, Section80, SubTitle, TitleInnerSection, TitleSection } from "../DesignComponent/DesignComponents";

const DriveParticipation = ({
  text_before_title,
  title,
  description,
  card1,
  card2,
  card3,
  card4,
  removePonit,
  bgwhite,
}) => {
  return (
    <>
      <Section80 className={`${bgwhite ? "bg-white" : ""}`} style={{ backgroundColor: Color.theme_four }}>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-md-8">
              {text_before_title && (
                <SubTitle className="sub-title mb-2">
                  {text_before_title}
                </SubTitle>
              )}
              {title && <TitleSection className="mb-md-3 mb-0">{title}</TitleSection>}
              {description &&
                <Description className="description mb-4" style={{ color: Color.lighterGrey }}>{description}</Description>
              }
            </div>
          </div>
        </div>
        <InnerSection className="meet-angad right-align-img px-md-0 px-3">
          <div className="container">
            <div className="row align-items-center flex-md-row flex-column-reverse justify-content-center">
              <div className="col-md-5">
                <Contentwrapper className="content-wrapper">
                  {card1?.text_before_title && (
                    <SubTitle className="sub-title mb-2">
                      {card1?.text_before_title}
                    </SubTitle>
                  )}
                  <TitleInnerSection className="mb-3">
                    {card1?.title}
                  </TitleInnerSection>
                  <Description
                    className="description mb-0"
                    style={{ color: Color.lighterGrey }}
                  >
                    <ul className={`pb-2 mb-0 ${removePonit ? "list-call" : ""}`}>
                      {card1?.description?.map((item, i) => (
                        // <li className="pb-2 mb-0 ">{item?.list}</li>
                        <li key={i}>{item?.list}</li>
                      ))}
                    </ul>
                  </Description>
                </Contentwrapper>
              </div>
              <div className="col-md-5 d-flex justify-content-end">
                <img
                  src={card1?.image}
                  alt={card1?.title}
                  className="w-100 h-100 mb-md-0 mb-4"
                  style={{ maxWidth: "400px" }}
                  fetchpriority="low"
                />
              </div>
            </div>
          </div>
        </InnerSection>
        {card2 && (
          <InnerSection className="meet-angad left-align-img px-md-0 px-3">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-5 d-flex">
                  <img
                    src={card2?.image}
                    alt={card2?.title}
                    className="w-100 h-100 mb-md-0 mb-4"
                    style={{ maxWidth: "400px" }}
                    fetchpriority="low"
                  />
                </div>
                <div className="col-md-5">
                  <Contentwrapper className="content-wrapper float-end">
                    {card2?.text_before_title && (
                      <SubTitle className="sub-title mb-2">
                        {card2?.text_before_title}
                      </SubTitle>
                    )}
                    <TitleInnerSection className="mb-3">
                      {card2?.title}
                    </TitleInnerSection>
                    <Description
                      className="description mb-0"
                      style={{ color: Color.lighterGrey }}
                    >
                      <ul className={`pb-2 mb-0 ${removePonit ? "list-call" : ""}`}>
                        {card2?.description?.map((item, i) => (
                          <li key={i}>{item?.list}</li>
                        ))}
                      </ul>
                    </Description>
                  </Contentwrapper>
                </div>
              </div>
            </div>
          </InnerSection>
        )}
        {card3 && (
          <InnerSection className="meet-angad right-align-img px-md-0 px-3">
            <div className="container">
              <div className="row align-items-center flex-md-row flex-column-reverse justify-content-center">
                <div className="col-md-5">
                  <Contentwrapper className="content-wrapper">
                    {card3?.text_before_title && (
                      <SubTitle className="sub-title mb-2">
                        {card3?.text_before_title}
                      </SubTitle>
                    )}
                    <TitleInnerSection className="mb-3">
                      {card3?.title}
                    </TitleInnerSection>
                    <Description
                      className="description mb-0"
                      style={{ color: Color.lighterGrey }}
                    >
                      <ul className={`pb-2 mb-0 ${removePonit ? "list-call" : ""}`}>
                        {card3?.description?.map((item, i) => (
                          <li key={i}>{item?.list}</li>
                        ))}
                      </ul>
                    </Description>
                  </Contentwrapper>
                </div>
                <div className="col-md-5 d-flex justify-content-end">
                  <img
                    src={card3?.image}
                    alt={card3?.title}
                    className="w-100 h-100 mb-md-0 mb-4"
                    style={{ maxWidth: "400px" }}
                    fetchpriority="low"
                  />
                </div>
              </div>
            </div>
          </InnerSection>
        )}
        {card4 && (
          <InnerSection className="meet-angad left-align-img px-md-0 px-3 pb-0">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-5 d-flex">
                  <img
                    src={card4?.image}
                    alt={card4?.title}
                    className="w-100 h-100 mb-md-0 mb-4"
                    style={{ maxWidth: "400px" }}
                    fetchpriority="low"
                  />
                </div>
                <div className="col-md-5">
                  <Contentwrapper className="content-wrapper float-end">
                    {card4?.text_before_title && (
                      <SubTitle className="sub-title mb-2">
                        {card4?.text_before_title}
                      </SubTitle>
                    )}
                    <TitleInnerSection className="mb-3">
                      {card4?.title}
                    </TitleInnerSection>
                    <Description
                      className="description mb-0"
                      style={{ color: Color.lighterGrey }}
                    >
                      <ul className={`pb-2 mb-0 ${removePonit ? "list-call" : ""}`}>
                        {card4?.description?.map((item, i) => (
                          <li key={i}>{item?.list}</li>
                        ))}
                      </ul>
                    </Description>
                  </Contentwrapper>
                </div>
              </div>
            </div>
          </InnerSection>
        )}
      </Section80>
    </>
  );
};

export default DriveParticipation;

const InnerSection = styled.section`
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: 767px) {
          padding-top: 10px;
          padding-bottom: 10px;
        }
`;

const Contentwrapper = styled.div`
  // width: 75%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
