import {
  CLEAR_ERRORS,
  GET_ABOUT_PAGE_REQUEST,
  GET_ABOUT_PAGE_SUCCESS,
  GET_ABOUT_PAGE_FAIL,
} from "../constants/constants";

export const aboutPageReducer = (
  state = { about: null, isAbout: false, error: null },
  action
) => {
  switch (action.type) {
    case GET_ABOUT_PAGE_REQUEST:
      return {
        ...state,
        isAbout: true,
        error: null, // Clear any previous errors
      };
    case GET_ABOUT_PAGE_SUCCESS:
      return {
        ...state,
        isAbout: false,
        about: action.payload,
        error: null,
      };
    case GET_ABOUT_PAGE_FAIL:
      return {
        ...state,
        isAbout: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
