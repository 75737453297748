import {
  GET_OPERATIONAL_EXCELLENCE_FAIL,
  GET_OPERATIONAL_EXCELLENCE_REQUEST,
  GET_OPERATIONAL_EXCELLENCE_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/constants";

const initialState = {
    operationalexcellence: [],
    isoperationalexcellence: false,
  error: null,
};

const operationalexcellenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATIONAL_EXCELLENCE_REQUEST:
      return { ...state, isoperationalexcellence: true };
    case GET_OPERATIONAL_EXCELLENCE_SUCCESS:
      return {
        ...state,
        isoperationalexcellence: false,
        operationalexcellence: action.payload,
      };
    case GET_OPERATIONAL_EXCELLENCE_FAIL:
      return { ...state, isoperationalexcellence: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default operationalexcellenceReducer;
