import {
  GET_WORKFORCE_ENABLEMENT_FAIL,
  GET_WORKFORCE_ENABLEMENT_REQUEST,
  GET_WORKFORCE_ENABLEMENT_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/constants";

const initialState = {
  workforceenablement: [],
  isworkforceenablement: false,
  error: null,
};

const workforceenablementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFORCE_ENABLEMENT_REQUEST:
      return { ...state, isworkforceenablement: true };
    case GET_WORKFORCE_ENABLEMENT_SUCCESS:
      return {
        ...state,
        isworkforceenablement: false,
        workforceenablement: action.payload,
      };
    case GET_WORKFORCE_ENABLEMENT_FAIL:
      return { ...state, isworkforceenablement: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default workforceenablementReducer;
