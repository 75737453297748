import {
  CLEAR_ERRORS,
  GET_ALL_BLOGS_FAIL,
  GET_ALL_BLOGS_REQUEST,
  GET_ALL_BLOGS_SUCCESS,
  GET_BLOG_LIST_REQUEST,
  GET_BLOG_LIST_SUCCESS,
  GET_BLOG_LIST_FAIL,
  GET_BLOGS_REQUEST,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAIL,
  GET_LATEST_BLOG_FAIL,
  GET_LATEST_BLOG_REQUEST,
  GET_LATEST_BLOG_SUCCESS,
  GET_SINGLE_BLOG_FAIL,
  GET_SINGLE_BLOG_REQUEST,
  GET_SINGLE_BLOG_SUCCESS,
  GET_RECENT_BLOG_FAIL,
  GET_RECENT_BLOG_REQUEST,
  GET_RECENT_BLOG_SUCCESS,
  GET_CATEGORY_BLOG_FAIL,
  GET_CATEGORY_BLOG_REQUEST,
  GET_CATEGORY_BLOG_SUCCESS,
} from "../constants/constants";

const initialState = {
  blog: [],
  isBlog: false,
  blogsList: [],
  isBlogsList: false,
  latestBlog: [],
  islatestBlog: false,
  singleBlog: [],
  issingleBlog: false,
  recentBlog: [],
  isrecentBlog: false,
  allBlogs: [],
  isAllBlogs: false,
  postbyCategory: [],
  ispostbyCategory: false,
  error: null,
};

export const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG_LIST_REQUEST:
      return { ...state, isBlog: true };
    case GET_BLOG_LIST_SUCCESS:
      return { ...state, isBlog: false, blog: action.payload };
    case GET_BLOG_LIST_FAIL:
      return { ...state, isBlog: false, error: action.payload };

    case GET_BLOGS_REQUEST:
      return { ...state, isBlogsList: true };
    case GET_BLOGS_SUCCESS:
      return { ...state, isBlogsList: false, blogsList: action.payload };
    case GET_BLOGS_FAIL:
      return { ...state, isBlogsList: false, error: action.payload };

    case GET_LATEST_BLOG_REQUEST:
      return { ...state, islatestBlog: true };
    case GET_LATEST_BLOG_SUCCESS:
      return { ...state, islatestBlog: false, latestBlog: action.payload };
    case GET_LATEST_BLOG_FAIL:
      return { ...state, islatestBlog: false, error: action.payload };

    case GET_SINGLE_BLOG_REQUEST:
      return { ...state, issingleBlog: true };
    case GET_SINGLE_BLOG_SUCCESS:
      return { ...state, issingleBlog: false, singleBlog: action.payload };
    case GET_SINGLE_BLOG_FAIL:
      return { ...state, issingleBlog: false, error: action.payload };

    case GET_RECENT_BLOG_REQUEST:
      return { ...state, isrecentBlog: true };
    case GET_RECENT_BLOG_SUCCESS:
      return { ...state, isrecentBlog: false, recentBlog: action.payload };
    case GET_RECENT_BLOG_FAIL:
      return { ...state, isrecentBlog: false, error: action.payload };

    case GET_ALL_BLOGS_REQUEST:
      return { ...state, isAllBlogs: true };
    case GET_ALL_BLOGS_SUCCESS:
      return { ...state, isAllBlogs: false, allBlogs: action.payload };
    case GET_ALL_BLOGS_FAIL:
      return { ...state, isAllBlogs: false, error: action.payload };

    case GET_CATEGORY_BLOG_REQUEST:
      return { ...state, ispostbyCategory: true };
    case GET_CATEGORY_BLOG_SUCCESS:
      return {
        ...state,
        ispostbyCategory: false,
        postbyCategory: action.payload,
      };
    case GET_CATEGORY_BLOG_FAIL:
      return { ...state, ispostbyCategory: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};
