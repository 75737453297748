import React from "react";
import { styled } from "styled-components";
import { Font } from "../../theme/Font";
import { Color } from "../../theme/Color";
import { useSelector } from "react-redux";
import { CardContent, CardTitleH6, SubTitle } from "../DesignComponent/DesignComponents";

const Meet = ({data}) => {
  const Section = styled.section`
    padding-top: 120px;
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  `;
  const TitleSection = styled.div`
    font-size: 36px;
    font-family: ${Font.bold};
    color: ${Color.theme_first};
    @media (max-width: 767px) {
      font-size: 24px;
    }
  `;
  const CardImage = styled.img`
    width: 100%;
    border-radius: 8px;
  `;
  // const CardTitle = styled.h6`
  //   font-size: 18px;
  //   color: ${Color.theme_first};
  //   font-family: ${Font.basel};
  //   @media (max-width: 767px) {
  //     font-size: 16px;
  //   }
  // `;
  // const CardContent = styled.p`
  //   font-family: ${Font.basel};
  //   color: ${Color.theme_first};
  //   @media (max-width: 767px) {
  //     font-size: 14px;
  //   }
  // `;

  // const data = useSelector(
  //   (state) => state?.aboutPagePage?.about?.acf?.our_leadership
  // );

  return (
    <>
      <Section className="our_team text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-3 d-lg-block d-none"></div>
            <div className="col-xl-4 col-lg-6 p-lg-0">
              <TitleSection className="lh-base mb-3 fw-bold">
                {data?.title}
              </TitleSection>
            </div>
            <div className="col-xl-4 col-lg-3 d-lg-block d-none"></div>
          </div>
          <div className="row">
            {data?.team_members.map((item, i) => (
              <div className="col-lg-4 col-md-6 mt-lg-0 mb-3" key={i}>
                <div className="card border-0 text-center mb-3 bg-transparent">
                  <CardImage src={item?.team_member?.profile_image} alt={item?.team_member?.name} className="mx-auto mb-4"/>
                  <div className="card-body p-0">
                    <CardTitleH6 className="card-title">
                      {item?.team_member?.name}
                    </CardTitleH6>
                    <SubTitle>{item?.team_member?.position}</SubTitle>
                    <CardContent className="card-text">
                      {item?.team_member?.bio}
                    </CardContent>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
};

export default Meet;
