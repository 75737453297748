import {
  CLEAR_ERRORS,
  GET_ROLES_PERMISSIONS_FAIL,
  GET_ROLES_PERMISSIONS_REQUEST,
  GET_ROLES_PERMISSIONS_SUCCESS,                            
} from "../constants/constants";

const initialState = {
  rolespermissions: [],
  isrolespermissions: false,
  error: null,
};

const rolespermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_PERMISSIONS_REQUEST:
      return { ...state, isrolespermissions: true };
    case GET_ROLES_PERMISSIONS_SUCCESS:
      return { ...state, isrolespermissions: false, rolespermissions: action.payload };
    case GET_ROLES_PERMISSIONS_FAIL:
      return { ...state, isrolespermissions: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default rolespermissionsReducer;
