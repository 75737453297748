export const Color = {
  theme_first: "#1C1C4B",
  theme_secound: "#272768",
  theme_three: "#F0E8DF",
  theme_four: "#F8F5F1",
  theme_five: "#C3C9D5",
  white: "#FFFFFF",
  black: "#000000",
  text: "#292929",

  darkBlack: "#292929",
  lightGrey: "#F7F6F9",
  darkGrey: "#C3C9D5",
  darkNavy: "#121240",
  lightNavy: "353555",
  yelow: "#FFC000",
  yelloStars: "#FFC000",
  beige: "#F6F3F0",
  darkBeige: "#F0E8DF",
  darkskyblue: "#0052CC",
  lightskyblue: "#e5eefa",

  lightwhite: "#FFFFFFB2",
  lightGreen: "#687C82",
  lightBlack: "#000000B2",
  lightYellow: "#FAEDCD",
  lightBrown: "#38202AB2",
  lightText: "#292929b2",
  lightBlue: "#EAF5FE",
  lighterGrey: "#696969",
};
