import {
  CLEAR_ERRORS,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
} from "../constants/constants";

export const reportReducer = (
  state = { report: [], isReport: false },
  action
) => {
  switch (action.type) {
    case GET_REPORT_REQUEST:
      return {
        isReport: true,
      };
    case GET_REPORT_SUCCESS:
      return {
        isReport: false,
        report: action.payload,
      };
    case GET_REPORT_FAIL:
      return {
        isReport: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
