import {
  CLEAR_ERRORS,
  GET_CONTINUOUSIMPROVEMENT_FAIL,
  GET_CONTINUOUSIMPROVEMENT_REQUEST,
  GET_CONTINUOUSIMPROVEMENT_SUCCESS,
  GET_RCA_FAIL,
  GET_RCA_REQUEST,
  GET_RCA_SUCCESS,
  GET_COMPETENCY_FAIL,
  GET_COMPETENCY_REQUEST,
  GET_COMPETENCY_SUCCESS,
} from "../constants/constants";

const initialState = {
  continuousimprovementPage: [],
  iscontinuousimprovementPage: false,
  error: null,
  rca: [],
  isRca: false,
  rcaError: null,
  competency: [],
  isCompetency: false,
  competencyError: null,
};

const continuousImprovementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTINUOUSIMPROVEMENT_REQUEST:
      return { ...state, iscontinuousimprovementPage: true };
    case GET_CONTINUOUSIMPROVEMENT_SUCCESS:
      return {
        ...state,
        iscontinuousimprovementPage: false,
        continuousimprovementPage: action.payload,
      };
    case GET_CONTINUOUSIMPROVEMENT_FAIL:
      return {
        ...state,
        iscontinuousimprovementPage: false,
        error: action.payload,
      };
    case GET_RCA_REQUEST:
      return { ...state, isRca: true };
    case GET_RCA_SUCCESS:
      return {
        ...state,
        isRca: false,
        rca: action.payload,
      };
    case GET_RCA_FAIL:
      return {
        ...state,
        isRca: false,
        rcaError: action.payload,
      };
    case GET_COMPETENCY_REQUEST:
      return { ...state, isCompetency: true };
    case GET_COMPETENCY_SUCCESS:
      return {
        ...state,
        isCompetency: false,
        competency: action.payload,
      };
    case GET_COMPETENCY_FAIL:
      return {
        ...state,
        isCompetency: false,
        competency: action.payload,
      };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default continuousImprovementReducer;
