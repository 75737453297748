import { Col } from "react-bootstrap";
import styled from "styled-components";
import { Color } from "../../theme/Color";
import { Font } from "../../theme/Font";
import { removeHtmlTags } from "../../utils/removeHtml";
import { Link } from "react-router-dom";
import he from 'he';

const DataItem = ({
  imageLink,
  categoryNames,
  resourceName,
  title,
  excerpt,
  slug,
  handleScroll,
  resourcetype,
}) => {
  // console.log(resourcetype);
  return (
    <>
      <Col md={6} lg={4} sm={6} className='mb-5 multiLine-draw-card' style={{overflow:"hidden"}}>
        <div className="img-wrapper mb-3" style={{height:"255px", overflow:"hidden"}}>
        <img
          className='w-100 mb-4 feature-img transition-transform duration-300 ease-in'
          src={imageLink}
          style={{ width:"401px", height: "255px", objectFit: "cover" }}
          alt={title}
          fetchpriority="low"
        />
        </div>
        <div className='d-flex mb-2 gap-2 flex-wrap align-items-center'>
          {resourcetype && (
            <>
              {resourceName?.map((item, i) => (
                <Resourcebtn key={i}>{item?.name}</Resourcebtn>
              ))}
            </>
          )}
        </div>
        <div className='d-flex mb-2 gap-2 flex-wrap align-items-center'>
          {categoryNames.map((item, i) => (
            <Categorybtn
              key={i}
              to={{
                pathname: `/category/?name=${item?.slug}`,
                state: { item: item },
              }}
            >
              {item?.name}
            </Categorybtn>
          ))}
        </div>
        <PostTitle
          to={resourcetype===true ? `/resource/${slug}` : `/${slug}`}
          className='multiLine-draw mb-2'
          onClick={handleScroll ? () => handleScroll() : null}
        >
          {he.decode(removeHtmlTags(title))}
        </PostTitle>
        {excerpt && (
        <Description className='mb-0'>
        {excerpt ? (
          excerpt.length > 140
            ? `${he.decode(removeHtmlTags(excerpt)).substring(0, 140)}...`
            : he.decode(removeHtmlTags(excerpt))
        ) : (
          ''
        )}
      </Description>
        )}
      </Col>
    </>
  );
};
export default DataItem;

const Categorybtn = styled(Link)`
  font-size: 14px;
  color: #B1A79C;
  background-color: ${Color.beige};
  transition: all 1s;
  padding: 10px;
  text-decoration: none;
  border-radius: 2px;
  font-family: ${Font.basel};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing:.078125rem;
  &:hover {
    color: ${Color.white};
    background-color: rgba(28, 28, 75);
  }
`;
const Resourcebtn = styled.button`
  font-size: 14px;
  color: ${Color.white};
  background-color: ${Color.theme_first};
  /* transition: all 1s; */
  padding: 2px 5px;
  text-decoration: none;
  border-radius: 2px;
  border: 0px !important;
  font-family: ${Font.basel};
  font-weight: 500;
  /* text-transform: uppercase; */
  &:hover {
    color: ${Color.white};
    background-color: ${Color.theme_first} !important;
  }
`;
const PostTitle = styled(Link)`
  font-size: 18px;
  font-family: ${Font.medium};
  font-weight: 500;
  color: ${Color.text};
  text-decoration: none;
`;
const Description = styled.p`
  font-family: ${Font.basel};
  color: ${Color.lighterGrey};
`;
