import {
  GET_COST_REDUCTION_FAIL,
  GET_COST_REDUCTION_REQUEST,
  GET_COST_REDUCTION_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/constants";

const initialState = {
  costreduction: [],
  iscostcostreduction: false,
  error: null,
};

const costreductionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COST_REDUCTION_REQUEST:
      return { ...state, iscostreduction: true };
    case GET_COST_REDUCTION_SUCCESS:
      return {
        ...state,
        iscostreduction: false,
        costreduction: action.payload,
      };
    case GET_COST_REDUCTION_FAIL:
      return { ...state, iscostreduction: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default costreductionReducer;
