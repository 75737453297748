import {
  CLEAR_ERRORS,
  GET_MARKETPLACE_FAIL,
  GET_MARKETPLACE_REQUEST,
  GET_MARKETPLACE_SUCCESS,
} from "../constants/constants";

const initialState = {
  marketplace: [],
  ismarketplace: false,
  error: null,
};

const marketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_MARKETPLACE_REQUEST:
      return { ...state, ismarketplace: true };
    case GET_MARKETPLACE_SUCCESS:
      return { ...state, ismarketplace: false, marketplace: action.payload };
    case GET_MARKETPLACE_FAIL:
      return { ...state, ismarketplace: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default marketplaceReducer;
