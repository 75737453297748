import {
  CLEAR_ERRORS,
  GET_TEMPLATE_BUILDER_FAIL,
  GET_TEMPLATE_BUILDER_REQUEST,
  GET_TEMPLATE_BUILDER_SUCCESS,
} from "../constants/constants";

const initialState = {
  templatebuilder: [],
  istemplatebuilder: false,
  error: null,
};

const templatebuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_TEMPLATE_BUILDER_REQUEST:
      return { ...state, istemplatebuilder: true };
    case GET_TEMPLATE_BUILDER_SUCCESS:
      return { ...state, istemplatebuilder: false, templatebuilder: action.payload };
    case GET_TEMPLATE_BUILDER_FAIL:
      return { ...state, istemplatebuilder: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default templatebuilderReducer;
