import {
  CLEAR_ERRORS,
  GET_GEMBA_WALKER_FAIL,
  GET_GEMBA_WALKER_REQUEST,
  GET_GEMBA_WALKER_SUCCESS,
} from "../constants/constants";

const initialState = {
  gembawalker: [],
  isgembawalker: false,
  error: null,
};

const gembawalkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_GEMBA_WALKER_REQUEST:
      return { ...state, isgembawalker: true };
    case GET_GEMBA_WALKER_SUCCESS:
      return { ...state, isgembawalker: false, gembawalker: action.payload };
    case GET_GEMBA_WALKER_FAIL:
      return { ...state, isgembawalker: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default gembawalkerReducer;
