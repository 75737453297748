import React from "react";
import { styled } from "styled-components";
import { Font } from "../../theme/Font";
import { Color } from "../../theme/Color";
import NorthEastArrow from "../../assets/NewSite/north_east_arrow.webp";
import {
  CardContent,
  CardTitle,
  Description,
  Section112,
  SubTitle,
  TitleSection,
} from "../DesignComponent/DesignComponents";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CardIcon = styled.img`
  // background-color: ${Color.theme_first};
  width: 45px;
  // height: 55px;
  // border: 1px solid ${Color.lightwhite};
  // border-radius: 8px;
  // padding: 12px;
`;

const CardButton = styled(Link)`
  font-family: ${Font.basel};
  font-size: 16px;
  color: ${Color.black};
  background-color: #1c1c4b1a;
`;

const ArrowImage = styled.img`
  width: 32px;
  height: 32px;
`;
const OurSolution = ({ data, bggrey }) => {
  const settings = {
    dots: false,
    // arrows: data?.solution_boxes?.length >= 5,
    arrows: false,
    infinite: true,
    speed: 6000,
    slidesToShow: 4.5,
    slidesToScroll: 0.5,
    // vertical: true, 
    // verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: true,
    draggable: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const IconBoxesCards = ({ data, iconKey }) => (
    // <div className="col-md-3 mb-3">
    <div className="card border-0 text-md-start mb-4 p-4 h-100">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <CardIcon src={data?.solution_box?.icon} alt={data?.solution_box?.title} fetchpriority="low" />
        <ArrowImage src={NorthEastArrow} alt={"NorthEastArrow"} />
      </div>
      <div className="card-body p-0">
        <CardTitle className="card-title mb-2">
          {data?.solution_box?.title}
        </CardTitle>
        <CardContent className="card-text mb-0 lh-base">
          {data?.solution_box?.description}
        </CardContent>
      </div>
      <div className="card-footer p-0 border-0 bg-transparent d-flex align-items-end">
        <CardButton
          to={data?.solution_box?.learn_more_button?.url}
          className="py-2 px-4 d-inline-block text-decoration-none rounded fw-bold"
          aria-label={data?.solution_box?.title}
        >
          <span>{data?.solution_box?.learn_more_button?.title || "Learn More"}</span>
        </CardButton>
      </div>
    </div>
    // </div>
  );

  return (
    <>
      <Section112
        className="why_leansuit text-white"
        style={{ backgroundColor: bggrey ? Color.lightGrey : Color.theme_first }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 text-center">
              <SubTitle className="sub-title mb-2" style={{ color: bggrey ? Color.darkBlack : Color.white }}>
                {data?.text_before_title}
              </SubTitle>
              <TitleSection className="mb-3" style={{ color: bggrey ? Color.darkBlack : Color.white }}>{data?.title}</TitleSection>
              <Description className="description mb-4" style={{ color: bggrey ? Color.lighterGrey : Color.lightwhite }}>
                {data?.description}
              </Description>
            </div>
          </div>
        </div>
        {data?.solution_boxes?.length <= 4 ? (
          <div className="container">
            <div className="row d-flex justify-content-center">
              {data?.solution_boxes?.map((value, index) => (
                <div className="col-md-3 mb-3 px-2">
                  <IconBoxesCards key={index} data={value} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="container-fluid p-0">
            <div className="row d-flex justify-content-center m-0">
              <div className="col-md-12 p-0">
                <Slider {...settings} className="solutions-slider">
                  {data?.solution_boxes?.map((value, index) => (
                      <IconBoxesCards key={index} data={value} />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        )}
      </Section112>
    </>
  );
};

export default OurSolution;
