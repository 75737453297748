import React, { useState } from "react";
import { styled } from "styled-components";
import { Font } from "../../theme/Font";
import { Color } from "../../theme/Color";
import { Description, Section112 } from "../DesignComponent/DesignComponents";
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';

const Button = styled.button`
  font-size: 18px;
  font-family: ${Font.basel};
  padding: 10px 15px;
  background-color: ${Color.white};
  color: ${Color.theme_first};
  border-radius: 4px;
  &.active {
    background-color: ${Color.theme_first};
    color: ${Color.white};
  }
  &.active:hover {
    background-color: ${Color.theme_first} !important;
    color: ${Color.white} !important;
  }
  &:hover {
    background-color: ${Color.white};
    color: ${Color.theme_first};
  }
`;

const TitleSection = styled.div`
  color: ${Color.text};
  font-size: 36px;
  font-family: ${Font.bold};
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const WeKnowManufacturing = ({ data }) => {
  // console.log(data);
  // Set the initial active tab to the index of the first tab
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const TabButtons = ({ data, index }) => (
    <Button
      className={`w-100 border-0 ${activeTab === index ? "active" : ""}`}
      onClick={() => handleTabClick(index)}
    >
      {data?.tab?.tab_name}
    </Button>
  );

  const TabContent = ({ data, index }) => (
    <div
    className="row align-items-center justify-content-between"
      key={index}
      style={{ display: activeTab === index ? "flex" : "none" }}
    >
      <div className="col-md-5">
      <TitleSection>{data?.tab?.title}</TitleSection>
      {data?.tab?.description?.map((item, idx) => (
        <div key={idx} className="d-flex align-items-start">
        {index === 0 ? (
          <AiFillCloseCircle style={{ marginRight: "10px", color: "#D41919", fontSize:"70px" }} />
        ) : (
          <AiFillCheckCircle style={{ marginRight: "10px", color: "#629B7C", fontSize:"70px"  }} />
        )}
        <Description style={{ color: Color.lighterGrey }}>{item?.list}</Description>
      </div>
      ))}
      </div>
      <div className="col-md-5">
        <img src={data?.tab?.image?.url} alt={data?.tab?.title} className="w-100"/>
      </div>
    </div>
  );

  return (
    <>
      <Section112 style={{backgroundColor:Color.beige}}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 mb-4">
              <div className="d-flex bg-white p-1 align-items-center justify-content-center">
                {data?.tabs?.map((value, index) => (
                  <TabButtons key={index} data={value} index={index} />
                ))}
              </div>
            </div>
            <div className="col-md-12">
              <TitleSection className="mb-4 text-center">
                {data?.title}
              </TitleSection>
              {data?.tabs?.map((value, index) => (
                <TabContent key={index} data={value} index={index} />
              ))}
            </div>
          </div>
        </div>
      </Section112>
    </>
  );
};

export default WeKnowManufacturing;