import React, { useEffect, useState } from "react";
import { Container, Navbar} from "react-bootstrap";
import StickyLogo from "../../../assets/NewSite/theleansuite-logo.jpg";
import Logo from "../../../assets/NewSite/white-logo.webp";
import "./Navbar.css";
import { Link } from "react-router-dom";

const DemoNavbarComponent = () => {

  const [isSticky, setSticky] = useState(false);
  const [logoSrc, setLogoSrc] = useState(Logo);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setSticky(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (isSticky) {
      setLogoSrc(StickyLogo);
    } else {
      setLogoSrc(Logo);
    }
  }, [isSticky]);

  return (
    <>
      <Navbar
        expand="xl"
        className={`navbar-dark py-2 ${isSticky ? 'sticky' : ''}`}
        style={{ backgroundColor: isSticky ? '#fff' : '#1c1c4b' }}
      >
        <Container>
          <Navbar.Brand>
          <Link to={"/"}>
            <img src={logoSrc} alt="theleansuite logo" style={{maxWidth:"145px"}} />
            </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};
export default DemoNavbarComponent;
