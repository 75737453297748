import {
  CLEAR_ERRORS,
  GET_POINT_LEADER_FAIL,
  GET_POINT_LEADER_REQUEST,
  GET_POINT_LEADER_SUCCESS,
} from "../constants/constants";

const initialState = {
  pointLeader: [],
  isPointLeader: false,
  error: null,
};

const pointLeaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POINT_LEADER_REQUEST:
      return { ...state, isPointLeader: true };
    case GET_POINT_LEADER_SUCCESS:
      return { ...state, isPointLeader: false, pointLeader: action.payload };
    case GET_POINT_LEADER_FAIL:
      return { ...state, isPointLeader: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default pointLeaderboardReducer;
