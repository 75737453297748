import {
  GET_SUGGESTION_FAIL,
  GET_SUGGESTION_REQUEST,
  GET_SUGGESTION_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/constants";

const initialState = {
suggestionPlatform: [],
issuggestionPlatform: false,
  error: null,
};

const suggestionPlateformReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTION_REQUEST:
      return { ...state, issuggestionPlatform: true };
    case GET_SUGGESTION_SUCCESS:
      return {
        ...state,
        issuggestionPlatform: false,
        suggestionPlatform: action.payload,
      };
    case GET_SUGGESTION_FAIL:
      return { ...state, issuggestionPlatform: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default suggestionPlateformReducer;
