import React, { useEffect, useState } from "react";

import { styled } from "styled-components";
import { Color } from "../../theme/Color";
import { Font } from "../../theme/Font";
import FooterLogo from "../../assets/footer/footer_logo.webp";
import AICPASOC from "../../assets/footer/aicpa-soc.webp";
import GDPR from "../../assets/footer/gdpr.webp";
import { Link } from "react-router-dom";
import AppStoreLogo from "../../assets/footer/appstore_logo.webp";
import GooglePlay from "../../assets/footer/googleplay_logo.webp";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { isValidEmail } from "../../utils/validation";
import { SubmitButton } from "../DesignComponent/DesignComponents";
import axios from "axios";
import { API_URL } from "../../redux/constants/constants";

const Section80 = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #090a43;
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const AnchorText = styled.a`
  color: ${Color.lightwhite};
  font-family: ${Font.basel};
  text-decoration: none;
  font-size: 14px;
  display: block;
`;
const ListTitle = styled.p`
  text-transform: uppercase;
  font-family: ${Font.medium};
  font-size: 13px;
  line-height:1.2;
`;
const ListItem = styled.li`
  font-family: ${Font.basel};
  list-style: none;
  margin-bottom: 16px;
`;
const ListLink = styled(Link)`
  color: ${Color.lightwhite};
  text-decoration: none;
`;

const Footer = () => {
  const [Email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const navigate = useHistory();
  const [pageUrl, setPageUrl] = useState(window.location.href);
  const [visitedPages, setVisitedPages] = useState([]);
  useEffect(() => {
    setPageUrl(window.location.href);
    const storedPages = JSON.parse(sessionStorage.getItem('visitedPages')) || [];
    setVisitedPages(storedPages);
  }, [window.location.href]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValidEmail(Email)) {
      setEmailError(true);
      return;
    } else {
      const data = [
        {
          Last_Name: "null",
          Email: Email,
          Page_Link: pageUrl,
          User_Visited_Links: visitedPages.join(', '),
          Lead_URL: visitedPages[0],
        },
      ];

      try {
        await axios.post(`${API_URL}custom/v1/lead`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        navigate.push({
          pathname: "/schedule-demo",
          state: Email,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleInput = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Section80 className="Footer text-white position-relative">
        <div className="container py-md-5 pb-0 pt-5">
          <div className="row">
            <div className="col-md-3">
              <Link to="/">
                <img
                  src={FooterLogo}
                  className="w-100 mb-4"
                  style={{ maxWidth: "150px" }}
                  loading="lazy"
                  alt={"TheLeansuite Logo"}
                />
              </Link>
            </div>
            <div className="col-md-3">
              <p className="fs-18" style={{ fontFamily: Font.basel, lineHeight: "1.2" }}>
                Sign up for a free demo to see LeanSuite in action
              </p>
            </div>
            <div className="col-md-6">
              <form
                className={`d-flex border-transparent ${emailError ? "border-danger" : ""
                  }`}
                onSubmit={handleSubmit}
              >
                <input
                  type="text"
                  placeholder="Enter your work email address"
                  value={Email}
                  onChange={handleInput}
                  className="bg-white bg-opacity-10 border-0 outline-0 text-white rounded-0 p-2 placeholder-white w-75"
                />
                <input
                  name="pagelink"
                  type="text"
                  value={pageUrl}
                  onChange={(e) => setPageUrl(e.target.value)}
                  hidden
                />
                <SubmitButton
                  className="border-0 px-4 w-auto"
                  type="submit"
                  style={{ borderRadius: "0 2px 2px 0", minWidth: "unset" }}
                >
                  {"Schedule Demo"}
                </SubmitButton>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr className="my-4" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-lg-0 mb-4">
              <ListTitle className="mb-3">Company</ListTitle>
              <ul className="list-group bg-transparent text-white">
                <ListItem>
                  <ListLink to="/aboutus">About Us</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/careers">Careers</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/theleansuite-partner-community">
                    Partner Network
                  </ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/press">Press</ListLink>
                </ListItem>
                {/* <ListItem>
                  <ListLink to="/404">Customer Success</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/404">ROI Calculator</ListLink>
                </ListItem> */}
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-lg-0 mb-4">
              <ListTitle className="mb-3">Platform</ListTitle>
              <ul className="list-group bg-transparent">
                <ListItem>
                  <ListLink to="/what-is-leansuite">LeanSuite 360</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/suggestion-platform-for-hr">Idea</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/issue-tracker-for-safety">
                    Failure Tag
                  </ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/continuous-improvement-for-operations">
                    Kaizen
                  </ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/competency-management">Competencies</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/learning-management-system">Training</ListLink>
                </ListItem>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-lg-0 mb-4">
              <ul className="list-group bg-transparent">
                <ListItem>
                  <ListLink to="/loss-and-cost-management">
                    Loss and Cost Management
                  </ListLink>
                </ListItem>
                {/* <ListItem>
                  <ListLink to="/404">Cost Tree</ListLink>
                </ListItem> */}
                <ListItem>
                  <ListLink to="/qa-matrix">QA Matrix</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/cilr-management">CILR</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/gemba-walker">Gemba Walker</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/rca">Root Cause Analysis</ListLink>
                </ListItem>
                <ListItem>
                  <ListLink to="/job-cover-matrix">Job Cover Matrix</ListLink>
                </ListItem>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-lg-0 mb-4">
              <ListTitle className="mb-3">Contact</ListTitle>
              <AnchorText
                className="mb-3 d-flex gap-2 align-items-center"
                href="tel:616-433-6688"
              >
                <MdPhone style={{ color: "#0052CC", fontSize: "20px" }} />
                <span>616-433-6688</span>
              </AnchorText>
              <AnchorText
                className="mb-5 d-flex gap-2 align-items-center"
                href="mailto:info@theleansuite.com"
              >
                <MdOutlineMailOutline
                  style={{ color: "#0052CC", fontSize: "20px" }}
                />
                <span>info@theleansuite.com</span>
              </AnchorText>
              <div className="d-flex align-items-center justify-content-between">
                <Link to="">
                  <img
                    src={AICPASOC}
                    loading="lazy"
                    className="w-100"
                    style={{ maxWidth: "120px" }}
                    alt={"AICPASOC"}
                  />
                </Link>
                <Link to="">
                  <img
                    loading="lazy"
                    src={GDPR}
                    className="w-100"
                    style={{ maxWidth: "120px" }}
                    alt={"GDPR"}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr className="my-4" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-md-0 mb-4">
              <div className="aap-links d-flex gap-2 justify-content-md-start justify-content-center">
                <a
                  href="https://www.linkedin.com/company/theleansuite"
                  style={{ width: "35px", height: "35px" }}
                  className="border border-white rounded-circle d-flex justify-content-center align-items-center" target="_blank" aria-label="Visit LinkedinIn">
                  <FaLinkedinIn
                    style={{ color: Color.white, fontSize: "12px" }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/theleansuite"
                  style={{ width: "35px", height: "35px" }}
                  className="border border-white rounded-circle d-flex justify-content-center align-items-center" target="_blank" aria-label="Visit Facebook">
                  <FaFacebookF
                    style={{ color: Color.white, fontSize: "12px" }}
                  />
                </a>
                <a
                  href="https://twitter.com/theleansuite"
                  style={{ width: "35px", height: "35px" }}
                  className="border border-white rounded-circle d-flex justify-content-center align-items-center" target="_blank" aria-label="Visit Twitter">
                  <FaTwitter style={{ color: Color.white, fontSize: "12px" }} />
                </a>
              </div>
            </div>
            <div className="col-md-3 mb-md-0 mb-4">
              <div className="aap-links d-flex gap-3 justify-content-md-start justify-content-center">
                <a href="https://apps.apple.com/us/app/theleansuite/id1660500404" target="_blank">
                  <img
                    loading="lazy"
                    src={AppStoreLogo}
                    className="w-100"
                    style={{ maxWidth: "140px" }}
                    alt={"Apple store button"}
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.leansuite" target="_blank">
                  <img
                    loading="lazy"
                    src={GooglePlay}
                    className="w-100"
                    style={{ maxWidth: "140px" }}
                    alt={"Google play store button"}
                  />
                </a>
              </div>
            </div>
            <div className="col-md-5 text-md-end text-center">
              <div className="policy-company">
                <Link
                  to="/legal/privacy"
                  className="text-decoration-none"
                  style={{ color: Color.lightwhite, fontFamily: Font.basel }}
                >
                  Privacy Policy
                </Link>
                <span> | </span>
                <Link
                  to="/legal/terms"
                  className="text-decoration-none"
                  style={{ color: Color.lightwhite, fontFamily: Font.basel }}
                >
                  Terms of Service
                </Link>
                <span> | </span>
                <Link
                  to=""
                  className="text-decoration-none"
                  style={{ color: Color.lightwhite, fontFamily: Font.basel }}
                >
                  Leansuite.com Corp
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Section80>
    </>
  );
};

export default Footer;
