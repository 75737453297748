import { OPEN_NAVBAR } from "../constants/constants";

export const navarReducer = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case OPEN_NAVBAR:
      return {
        isOpen: action.payload,
      };
    default:
      return state;
  }
};
