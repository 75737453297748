import React from "react";
import { styled } from "styled-components";
import { Color } from "../../theme/Color";
import { Description, Section112, SubTitle, TitleInnerSection, TitleSection } from "../DesignComponent/DesignComponents";


const CardIcon = styled.div`
width: 55px;
height: 55px;
border: 1px solid ${Color.darkBlack};
border-radius: 50%;
padding:12px;
color:  ${Color.yelow};
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
font-size:18px
`;
const InnerSection = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: 767px) {
          padding-top: 10px;
          padding-bottom: 10px;
        }
`;

const HowSteps = ({ data }) => {
    const IconBoxesCards = ({ data, index }) => (
        <InnerSection className={`row justify-content-center ${index % 2 === 0 ? "flex-row-reverse" : ""}`}>
            <div className="col-md-5">
                <img
                    src={data?.image}
                    alt={data?.title}
                    className="w-100"
                />
            </div>
            <div className="col-md-5 d-flex align-items-center">
                <div className="card border-0 mb-3 bg-transparent">
                    <CardIcon>
                        {data?.number}
                    </CardIcon>
                    <div className="card-body p-0 w-100">
                        <TitleInnerSection className="card-title">
                            {data?.title}
                        </TitleInnerSection>
                        <Description className="description" style={{ color: Color.lighterGrey }}
                            dangerouslySetInnerHTML={{
                                __html: data?.description
                            }}
                        />
                    </div>
                </div>
            </div>
        </InnerSection>
    );

    return (
        <>
            <Section112 className="why_leansuit" style={{ backgroundColor: Color.beige }}>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-8">
                            <SubTitle className="mb-3">{data?.text_before_title}</SubTitle>
                            <TitleSection className="mb-3">{data?.title}</TitleSection>
                            {data?.description &&
                            <Description className="description mb-4" style={{ color: Color.lighterGrey }}>{data?.description}</Description>
                            }
                        </div>
                    </div>
                    {data?.image_row?.image_box?.map((value, index) => (
                        <IconBoxesCards key={index} data={value} index={index} />
                    ))}
                </div>
            </Section112>
        </>
    );
};

export default HowSteps;

