import {
  CLEAR_ERRORS,
  GET_CUSTOMIZABLE_FIELDS_FAIL,
  GET_CUSTOMIZABLE_FIELDS_REQUEST,
  GET_CUSTOMIZABLE_FIELDS_SUCCESS,
} from "../constants/constants";

const initialState = {
  customizablefields: [],
  iscustomizablefields: false,
  error: null,
};

const customizablefieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_CUSTOMIZABLE_FIELDS_REQUEST:
      return { ...state, iscustomizablefields: true };
    case GET_CUSTOMIZABLE_FIELDS_SUCCESS:
      return { ...state, iscustomizablefields: false, customizablefields: action.payload };
    case GET_CUSTOMIZABLE_FIELDS_FAIL:
      return { ...state, iscustomizablefields: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default customizablefieldsReducer;
