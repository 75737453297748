import {
  CLEAR_ERRORS,
  GET_QA_MATRIX_FAIL,
  GET_QA_MATRIX_REQUEST,
  GET_QA_MATRIX_SUCCESS,
} from "../constants/constants";

const initialState = {
  qamatrixPage: [],
  isqamatrixPage: false,
  error: null,
};

const qamatrixPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case   GET_QA_MATRIX_REQUEST:
      return { ...state, isqamatrixPage: true };
    case GET_QA_MATRIX_SUCCESS:
      return { ...state, isqamatrixPage: false, qamatrixPage: action.payload };
    case GET_QA_MATRIX_FAIL:
      return { ...state, isqamatrixPage: false, error: action.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null };
    default:
      return state;
  }
};

export default qamatrixPageReducer;
